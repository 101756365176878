import { combine, createEffect, createEvent, sample } from 'effector';

import { createRequestFx } from '../../app/http/Http.js';
import { $coclient } from '../../user/User.js';
import { $internetService } from '../InternetService.js';

const $data = combine([
  $coclient,
  $internetService
// eslint-disable-next-line camelcase
], ([v_uid, service]) => ({
  v_uid,
  v_conumvolume: service ? service.conumvolume : null
}));

const beUnBlockFx = createEffect(data => createRequestFx({
  data,
  config: {
    path: 'be_unblock',
    method: 'put'
  }
}));

export const unBlockEvent = createEvent();

sample({
  clock: unBlockEvent,
  source: $data,
  target: beUnBlockFx
});

export const onUnBlockSuccessFx = createEffect();
sample({
  clock: beUnBlockFx.doneData,
  target: onUnBlockSuccessFx
});

export const onUnBlockErrorFx = createEffect();
sample({
  clock: beUnBlockFx.failData,
  target: onUnBlockErrorFx
});
