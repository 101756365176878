<template>
<chips-nav :links="links" />
</template>

<script setup>
import ChipsNav from '../../chips-nav/ChipsNav.vue';
const links = [
  {
    text: 'Личные данные',
    to: { name: 'user-data' }
  },
  {
    text: 'Пополнение баланса',
    to: { name: 'user-balance' }
  },
  {
    text: 'Смена пароля',
    to: { name: 'user-password' }
  }
];
</script>
