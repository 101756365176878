<template>
<b-select @update:value="setService"
          :list="list"
          compare-key="conum"
          show-key="nmbillgroup"
          label="Лицевой счет"
          size="large"
          class="select-service"
          :value="selectedService">
  <template #item="{item}">
    {{ item.nmbillgroup }} ({{ item.conum }})
  </template>
</b-select>
</template>

<script setup>
import { BSelect } from 'uikit';
import { useRouter, useRoute } from 'vue-router';

defineProps({
  list: { type: Array, default: () => [] },
  selectedService: { type: Object, required: true }
});

const router = useRouter();
const route = useRoute();

const setService = service => {
  router.push({
    name: route.name,
    params: {
      nmbillgroup: service.nmbillgroup,
      conum: service.conum
    }
  });
};
</script>

<style lang="scss">
.select-service {
  width: 360px;
  //TODO move to uikit

  .b-select__list{
    z-index: 10;
  }
}
</style>