<template>
<b-dialog-confirm ref="dialog"
                  @ok="okEvent"
                  @close="closeEvent"
                  ok-text="Понятно">
  <template #title>
    <div class="flex-center-y">
      <b-icon name="check_circled" theme="green" class="mr-8" />
      Пароль изменен
    </div>
  </template>
  <template #default>
    Пароль к личному кабинету изменен.
    <br />
    Сейчас вы можете
    <b-link :to="{name: 'user-balance'}">пополнить баланс</b-link>
    или перейти на страницу персональной информации
    <b-link :to="{name: 'user'}">персональной информации</b-link>
  </template>
</b-dialog-confirm>
</template>

<script>
export default {};
</script>

<script setup>
import { BDialogConfirm, BIcon, BLink } from 'uikit';
import { ref } from 'vue';
const emit = defineEmits({
  ok: () => true,
  close: () => true
});

const dialog = ref(null);
const okEvent = () => emit('ok');
const closeEvent = () => emit('close');

const open = () => dialog.value.open();
const close = () => dialog.value.close();
defineExpose({ open, close });
</script>