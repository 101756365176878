<template>
<select-service v-if="phoneService" :selected-service="phoneService" :list="phoneList" />
</template>

<script setup>
import { useStore } from 'effector-vue/composition.mjs';

import SelectService from '../services/SelectService.vue';
import { $phoneList } from '../services/Services.js';

import { $phoneService } from './PhoneService.js';
const phoneService = useStore($phoneService);
const phoneList = useStore($phoneList);
</script>
