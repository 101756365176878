<template>
<b-dialog-confirm ref="dialog"
                  @ok="okEvent"
                  cancel-text="Отменить"
                  ok-text="Сменить тарифный план">
  <template #title>
    Смена тарифного плана
  </template>
  <template #default>
    Вы выбрали тарифный план:  <br />
    <b>{{ selectedTariff.nmbplan }}</b>
    <br />
    <br />
    <template v-if="selectedTariff.description">
      Описание тарифного плана: <br />
      <b> {{ selectedTariff.description }}</b>
      <br />
      <br />
    </template>
    Начало действия: <br />
    <b> {{ startDate }}</b>
  </template>
</b-dialog-confirm>
</template>

<script>
export default {};
</script>

<script setup>
import { addMonths, startOfMonth, format } from 'date-fns';
import { useStore } from 'effector-vue/composition.mjs';
import { BDialogConfirm } from 'uikit';

import { ref } from 'vue';

import { changeBp } from './BeChangeBp.js';
import { $selectedTariff } from './Tariff.js';

const dialog = ref(null);
const okEvent = () => {
  changeBp();
  close();
};
const selectedTariff = useStore($selectedTariff);
const startDate = format(startOfMonth(addMonths(new Date(), 1)), 'dd.MM.yyyy');
const open = () => dialog.value.open();
const close = () => dialog.value.close();
defineExpose({ open, close });
</script>
