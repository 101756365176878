import { createStore } from 'effector';

import { getServicesFx } from '../services/Services.js';

export const $phoneServiceTable = createStore([]);

$phoneServiceTable.on(getServicesFx.doneData, (_, response) => {
  const data = response.data.data;
  const opr = {
    service: 'Определитель номера',
    status: data.v_statusopr ? 'Включена' : 'Не включена',
    amount: data.v_oprprice ? `${data.v_oprprice} руб.` : '0,00 руб.'
  };
  const per = {
    service: 'Переадресация',
    status: data.v_statusper ? 'Включена' : 'Не включена',
    amount: data.v_perprice ? `${data.v_oprprice} руб.` : '0,00 руб.'
  };
  return [opr, per];
});