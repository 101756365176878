<template>
<b-dialog-confirm ref="dialog"
                  @ok="okEvent"
                  ok-text="Понятно">
  <template #title>
    <div class="flex-center-y">
      <b-icon name="cancel" theme="red" class="mr-8" />
      Что-то пошло не так
    </div>
  </template>
  <template #default>
    Не удалось изменить пароль, попробуйте позже
  </template>
</b-dialog-confirm>
</template>

<script>
export default {};
</script>

<script setup>
import { BDialogConfirm, BIcon } from 'uikit';
import { ref } from 'vue';

const emit = defineEmits({
  ok: () => true
});

const dialog = ref(null);
const okEvent = () => emit('ok');

const open = () => dialog.value.open();
const close = () => dialog.value.close();
defineExpose({ open, close });
</script>