<template>
<b-dialog-confirm ref="dialog"
                  @ok="okEvent"
                  cancel-text="Отменить"
                  ok-type-btn="outlined"
                  ok-text="Включить блокировку">
  <template #title>
    <div class="flex-center-y align-items-flex-start">
      <b-icon name="warning_triangle" theme="orange" class="mr-8" />
      Вы собираетесь включить временную блокировку доступа к услуге с {{ nextDay }}
    </div>
  </template>
  <template #default>
    Блокировка будет действовать до тех пор, пока вы самостоятельно не отключите её.
    <br />
    <br />
    Вы сможете отменить блокировку самостоятельно в любой момент.
    <br />
    <br />
    При подключении услуги с вашего лицевого счета спишется плата за подключение в размере 50 рублей.
    <br />
    <br />
    Ежемесячная абонентская плата за услугу составляет 60 рублей.
  </template>
</b-dialog-confirm>
</template>

<script setup>
import { format, addDays } from 'date-fns';
import { BDialogConfirm, BIcon } from 'uikit';
import { ref } from 'vue';

import { blockEvent } from './Block.js';

const dialog = ref(null);
const okEvent = () => {
  blockEvent();  close();
};

const nextDay = format(addDays(new Date(), 1), 'dd.MM.yyyy');

const open = () => dialog.value.open();
const close = () => dialog.value.close();
defineExpose({ open, close });
</script>