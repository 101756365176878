import StatisticBillPage from '../statistic/StatisticBillPage.vue';
import StatisticPaymentsPage from '../statistic/StatisticPaymentsPage.vue';
import StatisticServicePage from '../statistic/StatisticServicePage.vue';

import * as tvRouter from './RouterConst.js';
import TvStatisticPage from './statistic/TvStatisticPage.vue';
import TvInfoPage from './TvInfoPage.vue';
import TvPage from './TvPage.vue';
import TvServicePage from './TvServicePage.vue';
import TvServiceSettingPage from './TvServiceSettingPage.vue';
export default [
  {
    name: tvRouter.TV_ROUTE,
    path: '/tv',
    component: TvPage,
    children: [
      {
        name: tvRouter.TV_SERVICE_ROUTE,
        path: '/tv/:nmbillgroup/:conum',
        component: TvServicePage,
        children: [
          {
            name: tvRouter.TV_INFO_ROUTE,
            path: 'info',
            component: TvInfoPage
          },
          {
            name: tvRouter.TV_STATISTIC_ROUTE,
            path: 'statistic',
            component: TvStatisticPage,
            redirect: () => ({ name: tvRouter.TV_STATISTIC_PAYMENTS }),
            children: [
              {
                name: tvRouter.TV_STATISTIC_PAYMENTS,
                path: 'payments',
                component: StatisticPaymentsPage
              },
              {
                name: tvRouter.TV_STATISTIC_BILL,
                path: 'bill',
                component: StatisticBillPage
              },
              {
                name: tvRouter.TV_STATISTIC_SERVICE,
                path: 'service',
                component: StatisticServicePage
              }
            ]
          },
          {
            name: tvRouter.TV_SERVICE_SETTING,
            path: 'setting',
            component: TvServiceSettingPage
          }
        ]
      }
    ]
  }

];