import { combine, createEvent, restore } from 'effector';

import { $services } from './Services.js';

export const serviceByRouteParamsFabric = () => {
  const setServiceParams = createEvent();
  const $serviceParams = restore(setServiceParams, null);
  
  const $service =  combine({
    list: $services,
    params: $serviceParams
  }).map(({ list, params }) => {
    if (!params) return null;
    const { nmbillgroup, conum } = params;
    return list.find(service => service.nmbillgroup === nmbillgroup && service.conum === conum);
  });
  return {
    setServiceParams,
    $service
  };
};