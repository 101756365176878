<template>
<chips-nav :links="nav" />
<router-view />
</template>

<script setup>

import ChipsNav from '../chips-nav/ChipsNav.vue';

import { TV_INFO_ROUTE, TV_SERVICE_SETTING, TV_STATISTIC_ROUTE } from './RouterConst.js';

const nav = [
  { text: 'Информация о сервисе', to: { name: TV_INFO_ROUTE } },
  { text: 'Пополнение баланса', to: { name: 'user-balance' } },
  { text: 'Управление услугами', to: { name: TV_SERVICE_SETTING } },
  { text: 'Статистика', to: { name: TV_STATISTIC_ROUTE } }
];
</script>