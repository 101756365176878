import { createRouter, createWebHistory } from 'vue-router';

import BalancePage from '../balance/BalancePage.vue';
import InternetRoute from '../internet/Router.js';
import LoginPage from '../login/LoginPage.vue';
import NewsRoute from '../news/Router.js';
import PhoneRoute from '../phone/Router.js';
import PWip from '../pilot/PWip.vue';
import TvRoute from '../tv/Router.js';
import PUserWrapper from '../user/content/UserContent.vue';
import UserChangePassword from '../user/UserChangePassword.vue';
import UserPage from '../user/UserPage.vue';

import AppWrapper from './AppWrapper.vue';
const routes = [
  {
    name: 'login',
    path: '/login',
    component: LoginPage
  },
  {
    name: 'home',
    path: '/',
    component: AppWrapper,
    redirect: () => 'user',
    children: [
      {
        name: 'user',
        path: '/user',
        component: PUserWrapper,
        redirect: () => '/user/data',
        children: [
          {
            name: 'user-data',
            path: '/user/data',
            component: UserPage
          },
          {
            name: 'user-balance',
            path: '/user/balance',
            component: BalancePage
          },
          {
            name: 'user-password',
            path: '/user/password',
            component: UserChangePassword
          }
        ]
      },
      ...InternetRoute,
      {
        name: 'tv',
        path: '/tv',
        component: PWip
      },
      ...PhoneRoute,
      ...NewsRoute,
      ...TvRoute

    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes // short for `routes: routes`
});
router.beforeEach((to, from, next) => {
  // old token 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ2X2NvY2xpZW50IjoiMTAwMjQ2MTY3Iiwidl9jb251bXZvbHVtZSI6Ijg3NjAxOTM5Iiwidl9pZF9kZXAiOiIyMjEiLCJpYXQiOjE2NDYzOTg3MTEsImV4cCI6MTY0NjQ4NTExMX0.lvW0-nf502hzodqUn_o9cUDQGU5mz9yQrr7oOGqWyZk'
  try {
    if (to.name === 'login') {
      next();
      return;
    }
    const token = localStorage.getItem('token');
    if (token) {
      next();
      return;
    } else throw new Error('token not found');
  } catch (e) {
    console.error(e);
    next({ name: 'login' });
  }
});

export { router };