<template>
<div class="empty-result">
  <section class="empty-result__content">
    <i class="beeline-icons color-inactive empty-result__icon">calendar</i>
    <span class="h5 mt-8 mb-8">Поиск не дал результатов</span>
    <span class="body2">В период который вы указали не было активных действий</span>
  </section>
</div>
</template>

<script>
export default {};
</script>

<script setup>

</script>

<style lang="scss">
.empty-result {
  padding-top: 133px;
  padding-bottom: 133px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__icon{
    font-size: 34px;
  }
}
</style>