import { createStore } from 'effector';
import { reset } from 'patronum';

const toManyRequestsCode = 429;
const perSec = 60;
const perMs = 1000;
const waitingMessage = 'Учетная запись заблокирована. Повторите через';
export const isLoginError = effect => {
  const $isError = createStore(false);
  const $errorMessage = createStore('');
  reset({
    clock: [effect, effect.doneData],
    target: [$isError, $errorMessage]
  });
  $isError.on(effect.failData, () => true);
  $errorMessage.on(effect.failData, (_, AxiosError) => {
    if (AxiosError?.response?.status === toManyRequestsCode) {
      const timeToUnBlock =  Number(AxiosError?.response?.data?.retry?.msBeforeNext) / perSec;
      if (timeToUnBlock > perMs) {
        return `${waitingMessage} ${Math.round(timeToUnBlock / perMs)} мин`;
      }  else {
        return `${waitingMessage} ${Math.round(timeToUnBlock / perMs * perSec)} сек`;
      }
    }
    return AxiosError?.response?.data?.message;
  });
  return { $isError, $errorMessage };
};
