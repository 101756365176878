<template>
<router-view />
</template>

<script setup>

import { useStore } from 'effector-vue/composition';

import { onMounted, watch } from 'vue';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router';

import { $internetList } from '../services/Services.js';

import { $internetService, getInternetServicesWithParams, setInternetService } from './InternetService.js';
import { INTERNET_INFO_ROUTE, INTERNET_ROUTE, INTERNET_SERVICE_ROUTE } from './RouterConst.js';

const list = useStore($internetList);
const router = useRouter();
const route = useRoute();

const findService = (nmbillgroup, conum) => list.value.find(service => service.conum == conum && service.nmbillgroup == nmbillgroup);
const redirect = (routeName, service) => {
  router.push({
    name: routeName,
    params: {
      nmbillgroup: service.nmbillgroup,
      conum: service.conum
    }
  });
};
const internetService = useStore($internetService);

watch(list, () => {
  if (list.value.length === 0) return;
  if (internetService.value != null) return;
  const { conum, nmbillgroup } = route.params;

  if (conum && nmbillgroup) {
    const finedService = findService(nmbillgroup, conum);
    if (finedService) {
      setInternetService(finedService);
      redirect(route.name, finedService);
      return;
    }
  }
  const service = list.value.at(0);
  setInternetService(service);
  redirect(INTERNET_SERVICE_ROUTE, service);
}, { immediate: true });

onMounted(() => {
  getInternetServicesWithParams();
  if (internetService.value && route.name === INTERNET_ROUTE) {
    redirect(INTERNET_INFO_ROUTE, internetService.value);
  }
});

onBeforeRouteUpdate(to => {
  const { conum, nmbillgroup } = to.params;
  if (conum && nmbillgroup) {
    const finedService = findService(nmbillgroup, conum);
    if (finedService) {
      setInternetService(finedService);
    }
  }
});
</script>
