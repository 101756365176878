import { format, parseISO } from 'date-fns';
import { attach, combine, createEffect, createEvent, createStore, sample } from 'effector';

import { formatBytes } from '../../app/FormatBytes.js';
import { createRequestFx } from '../../app/http/Http.js';
import { isLoaded } from '../../app/IsLoaded.js';
import { paginationFabric } from '../../app/Pagination.js';
import { dateRangeFabric } from '../../statistic/Date.js';
import { $internetService } from '../InternetService.js';

const beNumTrafficOriginalFx = createEffect(data => createRequestFx({
  data,
  config: {
    path: 'be_num_traffic',
    method: 'get'
  }
}));

const { $dateRange, setDateRange, $outputDateRange } = dateRangeFabric({
  start: null,
  end: null
});

const $data = combine({
  v_conum: $internetService.map(service => service ? service.conum : null),
  $outputDateRange
}).map(({ v_conum, $outputDateRange }) => ({
  v_conum,
  ...$outputDateRange
}));

const beNumTrafficWithDataFx = attach({
  effect: beNumTrafficOriginalFx,
  source: { $data },
  mapParams: (params, { $data }) => ({ ...params, ...$data })
});

const { effect: beNumTrafficFx, $pagination, api: paginationApi } = paginationFabric(beNumTrafficWithDataFx);

export const $trafficStat = createStore([]);
$trafficStat.on(beNumTrafficFx.doneData, (_, response) => {
  const MIN_PER_HOUR = 60;
  return response.data.data.map(item => ({
    startdate: format(parseISO(item.startdate), 'dd.MM.yyyy HH:mm'),
    enddate: format(parseISO(item.enddate), 'dd.MM.yyyy HH:mm'),
    ip: item.ip,
    duration: `${item.duration / MIN_PER_HOUR} мин.`,
    input: formatBytes(item.input),
    output: item.output == 0 ? 0 : formatBytes(item.output)
  }));
});

export const $isLoaded = isLoaded(beNumTrafficFx);

const resetTrafficStat = createEvent();

$trafficStat.reset(resetTrafficStat);
$isLoaded.reset(resetTrafficStat);
$dateRange.reset(resetTrafficStat);

export const getBeNumTraffic = createEvent();

sample({
  clock: getBeNumTraffic,
  target: [paginationApi.resetPagination, beNumTrafficFx]
});

export {
  $dateRange, setDateRange, $pagination, paginationApi, resetTrafficStat, beNumTrafficFx
};
