import { createEffect, sample } from 'effector';

import { createRequestFx } from '../app/http/Http.js';
import { isError } from '../app/is-error/index.js';

import { getUserInfoFx } from './UserInfo.js';

export const editUserInfoFx = createEffect(data => createRequestFx({
  data,
  config: {
    path: 'be_changeclient',
    method: 'put'
  }
}));
export const { $errorMessage: $editUserInfoErrorMessage } = isError(editUserInfoFx);
export const openFx = createEffect();
export const closeFx = createEffect();
export const errorUpdateAccountFx = createEffect();
sample({
  clock: editUserInfoFx.failData,
  target: [closeFx, errorUpdateAccountFx]
});
export const successUpdateAccountFx = createEffect();
sample({
  clock: editUserInfoFx.doneData,
  target: [successUpdateAccountFx, getUserInfoFx],
  fn: () => ({})
});
