<template>
<div class="bordered pl-24 pt-16 pr-16 pb-20">
  <div class="flex-center-y">
    <b-text :skeleton="loading" class="mr-16" type="h5"> Договор № {{ service.dog }}</b-text>
    <service-status v-if="!loading" :status="service.status || '1'" />
    <div v-if="phone" class="ml-auto flex-center-y pr-17">
      <b-icon :skeleton="loading" name="phone" size="small" class="mr-8" />
      <b-text :skeleton="loading" type="h5">{{ service.phone }}</b-text>
    </div>
  </div>
  <div class="flex-center-y justify-space-between">
    <b-list-view :skeleton="loading"
                 body="Текущий баланс"
                 :title="service.saldo || 'нет данных'"
                 :reversed="true" />
    <b-list-view :skeleton="loading"
                 body="Сумма к оплате"
                 :title="service.summ || 'нет данных'"
                 :reversed="true" />
    <b-list-view :skeleton="loading"
                 body="Сумма ежемесячного платежа"
                 :title="service.billsum || 'нет данных'"
                 :reversed="true" />
    <b-list-view :skeleton="loading"
                 body="Система расчетов"
                 :title="service.nmpaymentsystem || 'нет данных'"
                 :reversed="true" />
    <service-info-action @click="blockEvent" :loading="loading" :status="service.status || '1'" />
  </div>
</div>
</template>

<script setup>
import { BText, BListView, BIcon } from 'uikit';

import ServiceStatus from '../services/ServiceStatus.vue';

import ServiceInfoAction from './ServiceInfoAction.vue';

defineProps({
  service: { type: Object, required: true },
  phone: { type: Boolean, default: false },
  loading: { type: Boolean, default: false }
});
const emit = defineEmits({
  block: () => true
});
const blockEvent = () => emit('block');
</script>