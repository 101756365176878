<template>
<b-button :skeleton="loading"
          size="small"
          v-bind="attrs">
  {{ text }}
</b-button>
</template>

<script setup>

import { BButton } from 'uikit';
import { computed } from 'vue';

const props = defineProps({
  status: {
    required: true,
    validator: value => {
      if (value === null) return true;
      return ['number', 'string'].includes(typeof value);
    }
  },
  loading: { type: Boolean, default: false }
});
const activeAttrs = {
  'type': 'outlined',
  'left-icon': 'ablock'
};
const blockAttrs = {
  'type': 'plain',
  'left-icon': 'check_circled'
};
const attrs = computed(() => {
  if (props.status == '1') return activeAttrs;
  if (props.status == '2') return blockAttrs;
  return activeAttrs;
});
const text = computed(() => {
  if (props.status == '1') return 'Заблокировать';
  if (props.status == '2') return 'Разблокировать';
  return 'Подключить';
});
</script>
