import { attach, combine, createEffect, createEvent, createStore } from 'effector';

import { createRequestFx } from '../app/http/Http.js';
import { isLoaded } from '../app/IsLoaded.js';
import { serviceByRouteParamsFabric } from '../services/ServiceByRouteParams.js';

import { dateRangeFabric } from './Date.js';

const beNumPaymentsOriginalFx =  createEffect(data => createRequestFx({
  data,
  config: {
    path: 'be_num_payments',
    method: 'get'
  }
}));

const { $service, setServiceParams } = serviceByRouteParamsFabric();

const { $dateRange, setDateRange, $outputDateRange } = dateRangeFabric({
  start: null,
  end: null
});

const $data = combine({
  v_cobillgroup: $service.map(service => service ? service.cobillgroup : null),
  $outputDateRange
}).map(({ v_cobillgroup, $outputDateRange }) => ({
  v_cobillgroup,
  ...$outputDateRange
}));

const beNumPaymentsFx =  attach({
  effect: beNumPaymentsOriginalFx,
  source: { $data },
  mapParams: (params, { $data }) => ({ ...params, ...$data })
});

const $paymentsStat = createStore([]);

$paymentsStat.on(beNumPaymentsFx.doneData, (_, response) => {
  const grouped = [];
  for (const item of response.data.data) {
    const index = grouped.findIndex(group => group.key === item.opername);
    if (index !== -1) {
      grouped[index].items.push(item);
    } else {
      grouped.push({
        key: item.opername,
        items: [item]
      });
    }
  }
  return grouped;
});

const $isLoaded =  isLoaded(beNumPaymentsFx);

const resetPaymentsStat = createEvent();
$paymentsStat.reset(resetPaymentsStat);
$isLoaded.reset(resetPaymentsStat);
$dateRange.reset(resetPaymentsStat);
export {
  $dateRange,
  $isLoaded,
  $paymentsStat,
  beNumPaymentsFx,
  resetPaymentsStat,
  setDateRange,
  setServiceParams
};
