<template>
<section>
  <b-text class="mt-16" type="h4">Дополнительные услуги</b-text>
  <internet-select-service class="mt-16" />
  <tab-nav class="mt-8 mb-26" :links="nav" />
  <router-view />
</section>
</template>

<script setup>
import { BText } from 'uikit';

import TabNav from '../tab-nav/TabNav.vue';

import InternetSelectService from './InternetSelectService.vue';
import { INTERNET_BLOCK_ROUTE, INTERNET_STATIC_IP_ROUTE, INTERNET_TARIFF_ROUTE } from './RouterConst.js';

const nav = [
  { text: 'Постоянный IP-адрес', to: { name: INTERNET_STATIC_IP_ROUTE } },
  { text: 'Смена тарифного плана', to: { name: INTERNET_TARIFF_ROUTE } },
  { text: 'Временная блокировка', to: { name:  INTERNET_BLOCK_ROUTE } }
];
</script>