<template>
<footer class="b-footer">
  <div class="b-footer__support color-active">
    Телефон службы по работе с клиентами 8-800-700-9966, Факс 8-800-700-9966*4, e-mail
    <a href="mailto:fix@beeline.ru" class="b-footer__link">fix@beeline.ru</a>
  </div>
  <div class="b-footer__copyright">
    Информация, приведённые в Личном кабинете, носит справочный характер. Отображённые состояние Лицевого счёт, может отличаться от реальных данных, хранимых в финансовых системах ОАО “ВымпелКом” или других компаний – операторов, входящих в группу компаний ВымпелКом © Группа компаний “ВымпелКом”, 2011г. “Билайн” © Все права защищены
  </div>
</footer>
</template>

<script>
export default {};
</script>

<style lang="scss">
.b-footer{
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;

  &__support{
    @include fontStyle(subtitle3);
    font-weight: 500;
  }

  &__link{
    color: var( --color-text-link);
    text-decoration: none;

    &:hover{
      text-decoration: underline;
    }
  }

  &__copyright{
    padding-top: 24px;
    @include fontStyle(caption);
    color: var( --color-text-disabled);
    padding-bottom: 24px;
  }
}
</style>