<template>
<div class="h4 mt-26 mb-22">Статистика</div>
<tv-select-service />
<tab-nav :links="links" class="mt-8" />
<router-view class="mt-32" />
</template>

<script setup>

import TabNav from '../../tab-nav/TabNav.vue';

import { TV_STATISTIC_BILL, TV_STATISTIC_PAYMENTS, TV_STATISTIC_SERVICE } from '../RouterConst.js';
import TvSelectService from '../TvSelectService.vue';

const links = [
  { text: 'Статистика платежей', to: { name: TV_STATISTIC_PAYMENTS } },
  { text: 'Статистика по выставленным счетам', to: { name: TV_STATISTIC_BILL } },
  { text: 'Статистика по услугам', to: { name: TV_STATISTIC_SERVICE } }
];

</script>