<template>
<svg width="121" height="30" viewBox="0 0 121 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M30.7961 3.2088H17.6699V26.7913H30.7961V3.2088Z" fill="#FF5A00" />
  <path d="M18.5437 15C18.5437 10.2087 20.7961 5.95631 24.2524 3.20874C21.7087 1.20874 18.5 0 15 0C6.70874 0 0 6.70874 0 15C0 23.2913 6.70874 30 15 30C18.5 30 21.7087 28.7913 24.2524 26.7913C20.7913 24.0825 18.5437 19.7913 18.5437 15Z" fill="#EB001B" />
  <path d="M48.5049 15C48.5049 23.2913 41.7961 30 33.5049 30C30.0049 30 26.7961 28.7913 24.2524 26.7913C27.7524 24.0388 29.9612 19.7913 29.9612 15C29.9612 10.2087 27.7088 5.95631 24.2524 3.20874C26.7913 1.20874 30 0 33.5 0C41.7961 0 48.5049 6.75243 48.5049 15Z" fill="#F79E1B" />
  <g clip-path="url(#clip0_301_45574)">
    <path d="M86.5047 24.429H81.6443L84.6843 5.63159H89.5445L86.5047 24.429Z" fill="#00579F" />
    <path d="M104.124 6.09111C103.166 5.71077 101.645 5.29083 99.765 5.29083C94.9651 5.29083 91.5851 7.85035 91.5644 11.5097C91.5245 14.2095 93.9844 15.7091 95.8242 16.6093C97.7046 17.5292 98.3438 18.1296 98.3438 18.9496C98.3247 20.2088 96.8243 20.7893 95.4249 20.7893C93.4844 20.7893 92.4447 20.49 90.8646 19.7894L90.2246 19.4891L89.5444 23.7087C90.6844 24.2281 92.7846 24.6889 94.9651 24.7091C100.065 24.7091 103.385 22.1892 103.425 18.2896C103.444 16.1498 102.145 14.5101 99.3448 13.1703C97.6448 12.3102 96.6037 11.7303 96.6037 10.8503C96.6236 10.0503 97.4843 9.23084 99.4032 9.23084C100.983 9.1907 102.144 9.57051 103.024 9.95059L103.463 10.1502L104.124 6.09111Z" fill="#00579F" />
    <path d="M110.584 17.7697C110.985 16.6899 112.525 12.5104 112.525 12.5104C112.505 12.5505 112.924 11.4106 113.164 10.7108L113.504 12.3304C113.504 12.3304 114.424 16.8299 114.624 17.7697C113.865 17.7697 111.544 17.7697 110.584 17.7697ZM116.584 5.63159H112.824C111.665 5.63159 110.784 5.97127 110.284 7.19122L103.064 24.4287H108.164C108.164 24.4287 109.004 22.1087 109.184 21.609C109.744 21.609 114.705 21.609 115.425 21.609C115.564 22.269 116.005 24.4287 116.005 24.4287H120.505L116.584 5.63159Z" fill="#00579F" />
    <path d="M77.5849 5.63159L72.825 18.4496L72.3048 15.85C71.4248 12.8503 68.6649 9.59127 65.585 7.97077L69.9449 24.4091H75.0847L82.7244 5.63159H77.5849Z" fill="#00579F" />
    <path d="M68.4048 5.63159H60.5849L60.5049 6.0114C66.6049 7.5713 70.6449 11.3314 72.3047 15.8508L70.6047 7.21169C70.3249 6.01113 69.4648 5.67119 68.4048 5.63159Z" fill="#FAA61A" />
  </g>
  <defs>
    <clipPath id="clip0_301_45574">
      <rect width="60" height="19.4183" fill="white" transform="translate(60.5049 5.29083)" />
    </clipPath>
  </defs>
</svg>
</template>

<script>
export default {};
</script>

<script setup>

</script>
