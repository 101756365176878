<template>
<section v-if="!loading">
  <h4 class="h4 mt-16">Оплата банковской картой</h4>
  <div class="bordered px-24 py-24 mt-16 balance-form">
    <h5 class="h5">Выберите лицевой счет который хотите пополнить</h5>
    <b-select v-model:value="account"
              :error="accountError.length > 0"
              :left-icon="accountIcon"
              compare-key="nmbillgroup"
              :list="selectItems"
              class="mt-12"
              size="large"
              label="Лицевой счет">
      <template #item="{item}">
        <div class="flex-center-y">
          <b-icon :name="item.icon" class="mr-16" />
          {{ item.nmbillgroup }}
        </div>
      </template>
    </b-select>

    <h5 class="h5 mt-24">Пополнить баланс</h5>
    <b-text-field v-model:value="balance"
                  :error="balanceError.length >0"
                  type="number"
                  class="mt-12"
                  prefix="₽"
                  size="large"
                  label="Сумма на которую хотите пополнить" />
    <ul>
      <li>
        <span class="body2">
          Укажите сумму от 100 до 5000 рублей. Платежи возможны картами платежных систем VISA и MasterCard.
        </span>
      </li>
    </ul>
    <div class="flex-center-y mt-12">
      <balance-icon />
      <b-button @click="submit"
                size="small"
                class="ml-auto"
                type="contained"
                left-icon="credit_card">
        Оплатить
      </b-button>
    </div>
  </div>
  <div class="body2 mt-16">
    На следующем шаге вы перейдете на сервер компании RBS для заполнения и передачи информации о Вашей платежной
    карте.
    Данные о карте будут переданы по безопасному протоколу (SSL) в Авторизационный центр являются недоступными для
    "Билайн".
    <br />

    Сервер имеет сертификат соответствия  <b-link to="https://www.verisign.com/"> Verisign.com</b-link>
  </div>
  <div class="divider"></div>
  <span class="body2">
    Оплата происходит через автоматизированный сервер Универсальной платежной системы RBS.
  </span>
</section>
</template>

<script>
export default {};
</script>

<script setup>
import { useStore } from 'effector-vue/composition';
import { BSelect, BIcon, BTextField, BButton, BeelineIcons, BLink } from 'uikit';
import { useField, useForm } from 'vee-validate';
import { computed, watch } from 'vue';
import * as yup from 'yup';

import { $services, getServicesFx, INTERNET_TYPE, PHONE_TYPE, TV_TYPE } from '../services/Services.js';

import BalanceIcon from './BalanceIcon.vue';

const loading = useStore(getServicesFx.pending);
const serviceIcons = {
  [INTERNET_TYPE]: BeelineIcons.Wifi,
  [TV_TYPE]: BeelineIcons.Tv,
  [PHONE_TYPE]: BeelineIcons.Phone
};
const services = useStore($services);
const selectItems = computed(() => services.value
  .filter(service => service.nmbillgroup)
  .map(service => ({
    icon: serviceIcons[service.coc],
    nmbillgroup: service.nmbillgroup
  })));
const accountIcon = computed(() => {
  if (!account.value) return '';
  return account.value.icon;
});
const MIN_BALANCE = 100;
const MAX_BALANCE = 5000;
const validationSchema = yup.object({
  balance: yup.number()
    .transform(value => (Number.isNaN(value) ? undefined : value))
    .required()
    .positive()
    .min(MIN_BALANCE)
    .max(MAX_BALANCE),
  account: yup.object().required()
});
const { validate } = useForm({ validationSchema });

const { value: balance, errors: balanceError } = useField('balance');
const { value: account, errors: accountError } = useField('account');
const submit = async () => {
  try {
    const result = await validate();
    if (!result.valid) return;
    const data = {
      'pay.ctn': account.value.nmbillgroup,
      'pay.sum': balance.value,
      'pointid': '133169756805' //TODO fix me
    };
    const searchParams = (new URLSearchParams(data)).toString();
    const url = `https://oplata.beeline.ru/broadband?${searchParams}`;
    window.open(url, '_blank');
  } catch (e) {
    console.error(e);
  }
};
watch(selectItems, value => {
  if (value.length > 0 && !account.value) {
    account.value = value.at(0);
  }
}, { immediate: true });

</script>

<style lang="scss">
.balance-form {
  width: 552px;
}
</style>