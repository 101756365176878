<template>
<section>
  <b-text :skeleton="loadingPage" type="h5" class="mt-26">Статический IP-адрес</b-text>
  <b-text :skeleton="loadingPage" :skeleton-rows="10" type="body2" class="mt-8 internet-static-ip-content">
    Это постоянный адрес в&nbsp;сети Интернет.
    <br />
    <br />
    Фиксированный адрес отличается от&nbsp;динамического тем, что последний меняется каждый раз, когда&nbsp;Вы выходите в&nbsp;Интернет (устанавливаете УРЫ-соединение).
    <br />
    <br />
    В&nbsp;отличие от&nbsp;динамического Фиксированный 1Р-адрес закрепляется за&nbsp;Вашим логином и&nbsp;остается неизменным до&nbsp;момента отключения данной дополнительной услуги.
    <br />
    <br />
    Никаких дополнительных настроек услуга &laquo;Фиксированный 1Р- адрес&raquo; не&nbsp;требует.
    <br />
    <br />
    Достаточно подключить услугу &laquo;Фиксированный 1Р-адрес&raquo;.
    <br />
    <br />
    Абонентская плата за&nbsp;&laquo;Фиксированный 1Р-адрес&raquo; списывается ежедневно согласно условиям Вашего тарифного плана.
  </b-text>
  <b-button v-if="staticIpData"
            @click="openConfirm"
            type="outlined"
            size="medium"
            class="mt-16">
    Подключить постоянный IР-адрес
  </b-button>
  <b-dialog-confirm ref="confirmDialog"
                    @ok="confirmOkEvent"
                    ok-type-btn="outlined"
                    cancel-text="Отменить"
                    ok-text="Подключить постоянный IР-адрес">
    <template #title>
      Статический IP-адрес
    </template>

    Вы&nbsp;собираетесь подключить услугу &laquo;Постоянный IP-адрес&raquo;
    <br /><br />
    Стоимость подключения: <b>{{ staticIpData.v_cost }} руб</b>
    <br />
    Абонентская плата: <b>{{ staticIpData.v_abonpay }} руб</b>
  </b-dialog-confirm>
  <static-ip-connect-error-dialog ref="errorDialog" @ok="close(errorDialog)" />
</section>
</template>

<script setup>
import { useStore } from 'effector-vue/composition.mjs';
import { BText, BButton, BDialogConfirm } from 'uikit';
import { onMounted, ref } from 'vue';

import { beStatIpOnFx, onErrorFx, onSuccessFx } from './BeStatIpOn.js';
import { $internetStaticIp } from './StaticIp.js';
import StaticIpConnectErrorDialog from './StaticIpConnectErrorDialog.vue';
defineProps({
  loadingPage: { type: Boolean, default: false }
});
const confirmDialog = ref(null);
const openConfirm = () => confirmDialog.value.open();
const staticIpData = useStore($internetStaticIp);
const confirmOkEvent = () => {
  confirmDialog.value.close();
  beStatIpOnFx();
};
const onSuccess = () => {
  //TODO WIP
  console.log('success');
};
const errorDialog = ref(null);
const onError = () => {
  errorDialog.value.open();
};
const close = dialog => {
  dialog.close();
};
onMounted(() => {
  onSuccessFx.use(onSuccess);
  onErrorFx.use(onError);
});
</script>

<style lang="scss">
.internet-static-ip-content{
  width: 512px;
}
</style>