<template>
<svg width="119" height="31" viewBox="0 0 119 31" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M96.7161 8.88101C99.0286 8.88101 100.674 7.666 100.989 5.00098H98.716C98.481 5.94105 97.9311 6.41109 96.7184 6.41109C95.5034 6.41109 94.8759 5.94105 94.6808 5.00098H92.4082C92.7186 7.666 94.4037 8.88101 96.7161 8.88101Z" fill="black" />
  <path d="M50.0564 19.7204V10.0957H47.1963V24.1259H50.1762L55.9363 14.3393V24.1259H58.7965V10.0957H55.8188L50.0564 19.7204Z" fill="black" />
  <path d="M38.1925 10.2136C35.9266 10.2136 33.9932 11.2689 32.9933 13.2289C33.1352 10.0495 33.7671 8.15825 37.4476 7.66604L43.1701 6.88116L42.9351 3.74609L36.9775 4.56866C31.8426 5.27372 30.3926 9.23357 30.3926 15.4638C30.3926 20.9114 32.8625 24.399 37.4476 24.399C41.9152 24.399 44.3452 20.949 44.3452 16.9138C44.3452 12.8786 41.6425 10.2136 38.1925 10.2136ZM37.4476 21.3038C34.8602 21.3038 33.3325 19.4236 33.3325 16.6012V15.1512C33.8802 14.0537 35.3324 13.1912 37.4476 13.1912C39.6825 13.1912 41.4052 14.2488 41.4052 16.9138C41.4052 19.5788 40.035 21.3038 37.4476 21.3038Z" fill="black" />
  <path d="M114.576 10.0957V15.3459H109.011V10.0957H106.15V24.1259H109.011V18.246H114.576V24.1259H117.438V10.0957H114.576Z" fill="black" />
  <path d="M93.8165 19.7204V10.0957H90.9541V24.1259H93.934L99.6942 14.3393V24.1259H102.554V10.0957H99.5767L93.8165 19.7204Z" fill="black" />
  <path d="M63.9424 18.6783C63.6298 20.2459 63.1974 20.9132 62.3349 20.9132C61.8649 20.9132 61.5124 20.8733 61.2773 20.7957V24.0483C61.6299 24.2057 61.9447 24.3232 62.6875 24.3232C64.1774 24.3232 65.235 23.4607 65.7449 22.1282C66.0576 21.3057 66.2549 20.1683 66.4123 18.7182C66.5697 17.2682 66.6872 15.3482 66.6872 13.0756H70.4897V24.1281H73.3498V10.0957H64.2971C64.2971 14.3283 64.1796 17.1108 63.9424 18.6783Z" fill="black" />
  <path d="M86.8504 20.3239L86.8903 14.7588C86.9302 11.2313 84.7729 9.78125 81.6378 9.78125C79.0504 9.78125 76.7002 11.1914 76.3477 14.4838H79.0903C79.2477 13.1912 80.2654 12.7589 81.6378 12.7589C83.0435 12.7589 83.9858 13.1912 84.1277 14.6346L80.6201 16.0514C78.23 17.0314 76.4275 18.1289 76.4275 20.4414C76.4275 22.8315 77.76 24.3613 80.425 24.3613C82.2653 24.3613 83.4293 23.4966 84.3605 21.8071C84.5002 23.4966 85.6198 24.2438 87.5576 24.2438H88.3425V21.5389H88.1075C87.2029 21.5389 86.8504 21.1863 86.8504 20.3239ZM81.4804 21.4213C80.1479 21.4213 79.1679 21.0688 79.1679 19.8538C79.1679 18.7962 79.9905 18.3262 81.4028 17.7364L84.1343 16.6012L84.1055 20.4015C83.6355 20.9912 82.6954 21.4213 81.4804 21.4213Z" fill="black" />
  <path d="M12.1036 26.5693C18.4171 26.5693 23.5352 21.4532 23.5352 15.1421C23.5352 8.831 18.4171 3.71484 12.1036 3.71484C5.79001 3.71484 0.671875 8.831 0.671875 15.1421C0.671875 21.4532 5.79001 26.5693 12.1036 26.5693Z" fill="#FFC800" />
  <path d="M17.0233 23.6558C13.141 25.8951 8.93063 26.2965 6.24121 24.9551C9.68446 27.0059 14.1055 27.1833 17.8214 25.0393C21.5374 22.8931 23.5949 18.9776 23.5395 14.9712C23.3577 17.971 20.9055 21.4165 17.0233 23.6558Z" fill="black" />
  <path d="M13.553 4.55294C14.3401 5.91427 12.4377 8.48618 9.30267 10.2932C6.16761 12.1002 2.99042 12.4638 2.20333 11.1002C2.17007 11.0359 2.13903 10.965 2.11686 10.8918C1.91288 10.2577 2.15677 9.43734 2.74431 8.57265C2.83965 8.4374 2.93721 8.30437 3.03698 8.17356C3.92384 7.01842 5.04794 6.01626 6.38711 5.24469C7.72627 4.4709 9.15634 4.00086 10.5997 3.81019C10.7682 3.7858 10.9323 3.76806 11.0919 3.75698C12.134 3.67938 12.9654 3.87892 13.4133 4.37113C13.4665 4.43099 13.5131 4.49086 13.553 4.55294Z" fill="black" />
  <path d="M18.413 6.02749C19.7876 8.40651 17.014 12.577 12.2205 15.344C7.42697 18.111 2.42505 18.4259 1.05263 16.0468C0.82426 15.65 0.702317 15.2088 0.677928 14.7343C0.618064 16.3329 0.892993 17.958 1.53375 19.5012C1.62909 19.7318 1.7333 19.9601 1.84416 20.1863C1.84859 20.1951 1.85524 20.2062 1.85968 20.2151C2.27428 20.9312 2.88622 21.4944 3.64892 21.9024C6.33834 23.3413 10.8857 22.8469 15.1383 20.3925C19.3886 17.9381 22.0913 14.2509 22.191 11.2046C22.2199 10.3443 22.0403 9.53504 21.6301 8.8189C21.4749 8.5861 21.313 8.35773 21.1423 8.14045C20.1335 6.83676 18.8786 5.79913 17.484 5.05859C17.872 5.31357 18.1869 5.63506 18.413 6.02749C18.413 6.02749 18.4152 6.02971 18.4152 6.03193L18.413 6.02749Z" fill="black" />
  <path d="M12.1034 26.5693C18.4152 26.5693 23.5319 21.4532 23.5319 15.1421C23.5319 8.831 18.4152 3.71484 12.1034 3.71484C5.79155 3.71484 0.674805 8.831 0.674805 15.1421C0.674805 21.4532 5.79155 26.5693 12.1034 26.5693Z" fill="url(#paint0_radial_1378_41964)" />
  <defs>
    <radialGradient id="paint0_radial_1378_41964"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(12.1023 15.143) scale(11.4283 11.4314)">
      <stop offset="0.5" stop-color="white" stop-opacity="0" />
      <stop offset="1" stop-color="white" stop-opacity="0.35" />
    </radialGradient>
  </defs>
</svg>
</template>

<script>
export default {};
</script>
