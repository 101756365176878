<template>
<section>
  <statistic-control v-model:value="date" @submit="submit" :loading="loading" />
  <div v-if="loading || loadingPdf" class="box-loader">
    <b-circular-progress :loading="loading || loadingPdf" />
  </div>
  <b-table v-if="(items.length > 0 || isLoaded) && !loading && !loadingPdf"
           v-model="selectedCobill"
           :is-empty-result="isLoaded && items.length ===0 "
           class="statistic-bill_scrollable mt-16"
           show-select
           item-key="cobill"
           :fields="fields"
           :items="items">
    <template #total v-if="items.length > 0">
      <tr>
        <td class="b-table__td ">
          <div class="subtitle3">Итого</div>
        </td>
        <td></td>
        <td class="b-table__td ">
          <div class="subtitle3">{{ sum }} руб</div>
        </td>
        <td></td>
      </tr>
    </template>
  </b-table>
  <b-pagination v-if="items.length > 0 && isLoaded && !loading && !loadingPdf"
                :pagination="pagination"
                :pagination-api="paginationApi" />
  <b-button v-if="selectedCobill"
            @click="getPdf"
            class="mt-16"
            type="outlined"
            :disabled="loadingPdf"
            size="small">
    Подробнее
  </b-button>
</section>
</template>

<script setup>
import { useStore, useVModel } from 'effector-vue/composition';
import { BButton, BCircularProgress } from 'uikit';
import { computed, onBeforeMount, onBeforeUnmount } from 'vue';

import { onBeforeRouteUpdate, useRoute } from 'vue-router';

import { formatNumbers } from '../app/NumberFormat.js';
import { INTERNET_ROUTE } from '../internet/RouterConst.js';
import { PHONE_ROUTE } from '../phone/RouterConst.js';
import BPagination from '../services/BPagination.vue';
import BTable from '../services/BTable.vue';

import { TV_ROUTE } from '../tv/RouterConst.js';

import { $billStat,
         $dateRange,
         $isLoaded,
         getBeNumBill,
         $pagination, resetBillStat,
         setDateRange, paginationApi,
         setServiceParams, $cobill, getPdfInt, $loadingPdf, getPdfTel, getPdfKtv, $loadingBeNumBillFx } from './BeNumBill.js';
import StatisticControl from './StatisticControll.vue';
const dateRangeStore = useStore($dateRange);
const date = computed({
  get() {
    return dateRangeStore.value;
  },
  set(value) {
    setDateRange(value);
  }
});
const pagination = useStore($pagination);
const items = useStore($billStat);
const fields = [
  { title: 'Номер счёта', key: 'cobill' },
  { title: 'Дата выставления', key: 'operdate' },
  { title: 'Сумма счёта с НДС', key: 'amount', formatter: value => `${value} руб` },
  { title: 'Дата оплаты', key: 'paydate' }
];

const sum = computed(() => formatNumbers(items.value.reduce((buf, value) => buf + Number.parseFloat(value.amount), 0)));
const submit = () => {
  getBeNumBill();
};
const selectedCobill = useVModel($cobill);
const isLoaded = useStore($isLoaded);
const loading = useStore($loadingBeNumBillFx);
const route = useRoute();

const getPdf = () => {
  const matched = route.matched;
  const isRoute = routeName => matched.some(item => item.name === routeName);
  if (isRoute(INTERNET_ROUTE)) {
    getPdfInt();
  } else if (isRoute(PHONE_ROUTE)) {
    getPdfTel();
  } else if (isRoute(TV_ROUTE)) {
    getPdfKtv();
  }
};
const loadingPdf = useStore($loadingPdf);
onBeforeMount(() => {
  setServiceParams(route.params);
});
onBeforeRouteUpdate(() => {
  resetBillStat();
});
onBeforeUnmount(() => {
  resetBillStat();
});
</script>

<style lang="scss">
.statistic-bill {
  &_scrollable {
    max-height: 37vh;
    overflow-y: auto;
  }
}
</style>
