<template>
<section class="login-form">
  <div @keyup.enter="send" class="login-form__form bordered">
    <div class="flex-direction-column flex-center-y">
      <login-logo class="login-form__logo" />
      <div class="login-form__title">Личный кабинет</div>
    </div>
    <b-text-field v-model:value="login"
                  left-icon="user"
                  size="large"
                  class="mt-40"
                  label="Логин"
                  :error="loginError.length > 0" />
    <b-text-field v-model:value="password"
                  type="password"
                  size="large"
                  class="mt-16"
                  label="Пароль"
                  :error="passwordError.length > 0" />
    <b-text v-if="loginErrorMessage" class="pt-10 text-red">
      {{ loginErrorMessage }}
    </b-text>
    <b-button @click="send"
              resizing="fill-content"
              class="mt-24"
              :disabled="disabledSend">
      Вход
    </b-button>
    <b-linear-progress class="login-form__loading" :loading="loading" />
  </div>
</section>
</template>

<script setup>
import { useStore } from 'effector-vue/composition';
import { BButton, BLinearProgress, BTextField, BText } from 'uikit';
import { useField, useForm } from 'vee-validate';
import { computed } from 'vue';

import * as yup from 'yup';

import { loginFx, $loginErrorMessage } from './Login.js';
import LoginLogo from './LoginLogo.vue';

const validationSchema = yup.object({
  login: yup.string().required(),
  password: yup.string().required()
});

const { validate } = useForm({ validationSchema });

const { value: login, errors: loginError } = useField('login', null, { initialValue: '' });
const { value: password, errors: passwordError } = useField('password', null, { initialValue: '' });
const loading = useStore(loginFx.pending);
const loginErrorMessage = useStore($loginErrorMessage);
const disabledSend = computed(() => loading.value);
const send = async () => {
  try {
    const result = await validate();
    if (!result.valid) return;
    loginFx({
      login: login.value,
      password: password.value
    });
  } catch (e) {
    console.error(e);
  }
};
</script>

<style lang="scss">
.login-form {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  &__form {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 480px;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 80px;
    padding-bottom: 120px;
    position: relative;
    overflow: hidden;
  }

  &__logo {
    align-self: center;
  }

  &__loading {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__title {
    font-weight: 500;
    font-size: 25px;
    line-height: 28px;
    color: rgba(25, 28, 52, .7);
    padding-top: 16px;
  }
}
</style>
