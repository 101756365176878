import { createStore } from 'effector';
import { reset } from 'patronum';

export const isError = effect => {
  const $isError = createStore(false);
  const $errorMessage = createStore('');
  reset({
    clock: [effect, effect.doneData],
    target: [$isError, $errorMessage]
  });
  $isError.on(effect.failData, () => true);
  $errorMessage.on(effect.failData, (_, AxiosError) => AxiosError?.response?.data?.message ||
    AxiosError?.response?.data?.error?.errortext ||
    'Что-то пошло не так');
  return { $isError, $errorMessage };
};
