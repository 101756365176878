import { createEffect, createStore } from 'effector';

import { createRequestFx } from '../app/http/Http.js';

export const userInfoFx = createEffect(data => createRequestFx({
  data,
  config: {
    path: 'me',
    method: 'get'
  }
}));

export const $coclient = createStore(null);

$coclient.on(userInfoFx.doneData, (_, response) => response.data.data.v_coclient);