<template>
<section>
  <div v-if="loading" class="p-user__loading">
    <b-circular-progress :loading="loading" />
  </div>

  <section v-if="!loading" class="mt-16">
    <template v-if="services.length > 0">
      <div class="h4 color-active ">Доступные сервисы</div>

      <div class="p-user__services mt-16">
        <template :key="index" v-for="(service,index) in services">
          <p-user-service :service="service" />
        </template>
      </div>
    </template>
    <template v-if="info">
      <div class="flex-center-y mt-32">
        <b-icon name="user_verified" class="mr-8" size="small" />
        <span class="color-inactive overline uppercase">Личные данные</span>
      </div>
      <div class="h4 color-active mt-4">
        {{ info.v_namerussfull }}
      </div>
      <div class="body2 mt-4">
        Адрес проживания: {{ info.v_structaddrfact }}
      </div>
      <div class="p-user__info-columns mt-16">
        <div class="p-user__info-column bordered">
          <b-list-view data-test-user-phone body="Контактный телефон" :title="info.v_phone || 'нет данных'" :reversed="true" />
          <b-list-view data-test-user-phone body="Дополнительные контакты" :title="info.v_phones || 'нет данных'" :reversed="true" />
          <b-list-view data-test-user-sms body="Телефон для sms" :title="info.v_sms || 'нет данных'" :reversed="true" />
        </div>
        <div class="p-user__info-column bordered">
          <b-list-view data-test-user-email body="E-mail" :title="info.v_email || 'нет данных'" :reversed="true" />
          <b-list-view data-test-user-emailsms body="Дополнительный E-mail" :title="info.v_emailsms || 'нет данных'" :reversed="true" />
        </div>
      </div>
      <user-edit-info :info="info" />
      <b-button @click="showUserEditInfoModal"
                class="mt-16"
                type="outlined"
                left-icon="edit"
                size="small">
        Редактировать данные
      </b-button>
    </template>
  </section>
  <modal-success ref="successModal">Личные данные изменены</modal-success>
  <modal-error ref="errorModal" @result="showUserEditInfoModal">
    <b-text>Не удалось изменить личные данные</b-text>
    <b-text>{{ editUserInfoErrorMessage }}</b-text>
  </modal-error>
</section>
</template>

<script setup>
import { useStore } from 'effector-vue/composition';
import { BIcon, BListView, BCircularProgress, BButton, BText } from 'uikit';
import { computed, onBeforeMount, ref } from 'vue';

import { ModalSuccess, ModalError } from '../app/modal/index.js';
import { $services, getServicesFx } from '../services/Services.js';

import PUserService from './service/UserService.vue';
import { successUpdateAccountFx, errorUpdateAccountFx, openFx, $editUserInfoErrorMessage } from './UserEditInfo.js';
import UserEditInfo from './UserEditInfo.vue';
import { $userInfo, getUserInfoFx } from './UserInfo.js';

const services = useStore($services);
const info = useStore($userInfo);
const editUserInfoErrorMessage = useStore($editUserInfoErrorMessage);
const loadingServices = useStore(getServicesFx.pending);
const loadingUserInfo = useStore(getUserInfoFx.pending);
const loading = computed(() => loadingServices.value || loadingUserInfo.value);

const successModal = ref(null);
const errorModal = ref(null);
const showUserEditInfoModal = () => openFx();
const showSuccessModal = () => successModal.value.open();
const showErrorModal = () => errorModal.value.open();
successUpdateAccountFx.use(() => showSuccessModal());
errorUpdateAccountFx.use(() => showErrorModal());

onBeforeMount(() => {
  getUserInfoFx();
});

</script>

<style lang="scss">
.p-user {
  &__info-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
  }

  &__info-column {
    padding-left: 24px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &__services {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    row-gap: 16px;
  }

  &__loading{
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }
}
</style>
