<template>
<section>
  <b-text type="h5" class="mt-26">Временная блокировка</b-text>

  <b-text class="mt-10 internet-block-content" type="body2">
    В&nbsp;данном разделе вы&nbsp;можете временно заблокировать доступ в&nbsp;Интернет.
    <br />
    <br />
    Эта опция полезна в&nbsp;том случае, если вы&nbsp;оплатили доступ, но&nbsp;по&nbsp;каким- то&nbsp;причинам долгое время не&nbsp;сможете пользоваться сервисом (командировка, отпуск и&nbsp;т.д.).
    <br />
    <br />
    С&nbsp;услугой &laquo;Добровольная блокировка&raquo; вы&nbsp;можете заблокировать доступ в&nbsp;интернет на&nbsp;любой срок&nbsp;&mdash; абонентская плата по&nbsp;тарифному плану на&nbsp;протяжении всего периода блокировки будет снижена до&nbsp;60&nbsp;рублей в&nbsp;месяц.
    <br />
    <br />
    Разовая плата за&nbsp;установку &laquo;Добровольной блокировки&raquo; составляет 50&nbsp;рублей.
  </b-text>

  <b-button v-if="internetService && internetService.status === 1"
            @click="block"
            type="outlined"
            size="medium"
            class="mt-10">
    Включить временную блокировку услуги
  </b-button>
  <b-button v-if="internetService && internetService.status === 2"
            @click="unBlock"
            type="outlined"
            size="medium"
            class="mt-10">
    Отключить временную блокировку услуги
  </b-button>
  <internet-block-dialog ref="blockDialog" />
  <internet-block-error-dialog ref="blockErrorDialog" />
  <internet-block-success-dialog ref="blockSuccessDialog" />

  <internet-un-block-dialog ref="unBlockDialog" />
  <internet-un-block-error-dialog ref="unBlockErrorDialog" />
  <internet-un-block-success-dialog ref="unBlockSuccessDialog" />
</section>
</template>

<script setup>
import { useStore } from 'effector-vue/composition';
import { BText, BButton } from 'uikit';

import { onBeforeMount, ref } from 'vue';

import { $internetService } from '../InternetService.js';

import InternetUnBlockDialog from '../unblock/InternetUnBlockDialog.vue';

import InternetUnBlockErrorDialog from '../unblock/InternetUnBlockErrorDialog.vue';
import InternetUnBlockSuccessDialog from '../unblock/InternetUnBlockSuccessDialog.vue';

import { onUnBlockErrorFx, onUnBlockSuccessFx } from '../unblock/UnBlock.js';

import { onErrorFx, onSuccessFx } from './Block.js';
import InternetBlockDialog from './InternetBlockDialog.vue';
import InternetBlockErrorDialog from './InternetBlockErrorDialog.vue';
import InternetBlockSuccessDialog from './InternetBlockSuccessDialog.vue';

const blockDialog = ref(null);
const unBlockDialog = ref(null);
const block = () => {
  blockDialog.value.open();
};
const unBlock = () => {
  unBlockDialog.value.open();
};
const blockErrorDialog = ref(null);
const blockSuccessDialog = ref(null);
const internetService = useStore($internetService);
const unBlockErrorDialog = ref(null);
const unBlockSuccessDialog = ref(null);
onBeforeMount(() => {
  onErrorFx.use(() => {
    blockErrorDialog.value.open();
  });
  onSuccessFx.use(() => {
    blockSuccessDialog.value.open();
  });
  onUnBlockErrorFx.use(() => {
    unBlockErrorDialog.value.open();
  });
  onUnBlockSuccessFx.use(() => {
    unBlockSuccessDialog.value.open();
  });
});
</script>

<style lang="scss">

.internet-block-content{
  width: 512px;
}
</style>
