import { createApp } from 'vue';

import App from './App.vue';
import 'uikit/dist/uikit.css';
import 'uikit/src/styles/scss/_index.scss';
import { router } from './app/Router.js';

const app = createApp(App);
app.use(router);
app.mount('#app');
