import { attach, combine, createApi, createStore, sample } from 'effector';
import { reset } from 'patronum';

// eslint-disable-next-line max-lines-per-function
export const paginationFabric = effect => {
  const $page = createStore(1);

  const pageApi = createApi($page, {
    next: page => page + 1,
    prev: page => page - 1
  });
  const DEFAULT_PER_PAGE = 10;
  const $perPage = createStore(DEFAULT_PER_PAGE);

  const $totalRows = createStore(0);
  $totalRows.on(effect.doneData, (_, response) => response.data.total);

  const $pageCount = createStore(1);

  $pageCount.on(effect.doneData, (_, response) => response.data.pageCount);
  const $isNextPage = combine({ $pageCount, $page })
    .map(({ $pageCount, $page }) => $pageCount !== $page);

  const $isPrevPage = $page.map(page => page !== 1);

  const $viewRange = combine({ $page, $perPage, $totalRows })
    .map(({ $page, $perPage, $totalRows }) => {
      if ($totalRows === 0) return '';
      const startValue = (($page - 1) * $perPage) + 1;
      const start = $page === 1 ? 1 : startValue;
      const endValue = $page === 1 ? $perPage : (start + $perPage) - 1;
      const end = endValue > $totalRows ? $totalRows : endValue;
      return `${start}-${end} из ${$totalRows}`;
    });

  const $pagination = combine({
    $isNextPage,
    $isPrevPage,
    $perPage,
    $viewRange
  });

  const paginationFx = attach({
    effect,
    source: { $page, $perPage },
    mapParams: (data, { $page, $perPage }) => ({
      ...data,
      page: $page,
      perPage: $perPage
    })
  });

  sample({
    clock: [pageApi.next, pageApi.prev],
    target: paginationFx
  });

  const resetPagination = reset({ target: $page });

  return {
    effect: paginationFx,
    $pagination,
    api: {
      nextPage: pageApi.next,
      prevPage: pageApi.prev,
      resetPagination
    }
  };
};
