import { combine, createEffect, createEvent, sample } from 'effector';

import { createRequestFx } from '../../app/http/Http.js';
import { $coclient } from '../../user/User.js';
import { $phoneService } from '../PhoneService.js';

const $data = combine([
  $coclient,
  $phoneService
// eslint-disable-next-line camelcase
], ([v_uid, service]) => ({
  v_uid,
  v_conumvolume: service ? service.conumvolume : null
}));

const beBlockFx = createEffect(data => createRequestFx({
  data,
  config: {
    path: 'be_block',
    method: 'put'
  }
}));

export const blockEvent = createEvent();

sample({
  clock: blockEvent,
  source: $data,
  target: beBlockFx
});

export const onSuccessFx = createEffect();
sample({
  clock: beBlockFx.doneData,
  target: onSuccessFx
});

export const onErrorFx = createEffect();
sample({
  clock: beBlockFx.failData,
  target: onErrorFx
});
