import { format, parseISO } from 'date-fns';
import { createEffect, createStore } from 'effector';

import { createRequestFx } from '../app/http/Http.js';

export const $news = createStore([]);

export const beGetnews =  createEffect(data => createRequestFx({
  data,
  config: {
    path: 'be_getnews',
    method: 'get'
  }
}));

$news.on(beGetnews.doneData, (_, response) => {
  const data = response.data.data.map(newsItem => {
    newsItem.startdate =  format(parseISO(newsItem.startdate), 'dd.MM.yyyy');
    return newsItem;
  });
  return data;
});
