<template>
<template v-if="staticIpInProgress">
  <b-text type="h5" class="mr-16"> Заявка в работе </b-text>
</template>
<template v-else>
  <template v-if="staticIpData && staticIpData.v_enabled === 1">
    <section class="bordered pl-24 py-16 pr-16 flex-center-y">
      <b-text type="h5" class="mr-16"> Ваш IP-адрес: {{ staticIpData.v_ip }} </b-text>
      <b-label class="mr-16"
               type="outline"
               text="Активен"
               icon="check_circled"
               status="success" />
      <b-text type="h5"> {{ staticIpData.v_abonpay }} руб </b-text>
      <b-button @click="disconnect"
                class="ml-auto"
                type="outlined"
                size="small"
                left-icon="ablock">
        Отключить постоянный IP-адрес
      </b-button>
    </section>
  </template>
  <internet-static-ip-connect v-else :loading-page="loadingPage" />
</template>
<static-ip-disconnect-dialog ref="disconnectDialog" />
<static-ip-disconnect-success-dialog ref="disconnectSuccessDialog" />
<static-ip-disconnect-error-dialog ref="disconnectErrorDialog" />
</template>

<script setup>
import { useStore } from 'effector-vue/composition.mjs';
import { BText, BLabel, BButton } from 'uikit';

import { onBeforeMount, ref } from 'vue';

import { onErrorFx, onSuccessFx } from './BeStatIpOff.js';
import { $loadingPage } from './BeStatIpOn.js';
import { $internetStaticIp, $staticIpInProgress } from './StaticIp.js';
import InternetStaticIpConnect from './StaticIpConnect.vue';
import StaticIpDisconnectDialog from './StaticIpDisconnectDialog.vue';
import StaticIpDisconnectErrorDialog from './StaticIpDisconnectErrorDialog.vue';
import StaticIpDisconnectSuccessDialog from './StaticIpDisconnectSuccessDialog.vue';

const staticIpData = useStore($internetStaticIp);

const disconnectDialog = ref(null);

const disconnect = () => {
  disconnectDialog.value.open();
};
const loadingPage = useStore($loadingPage);
const disconnectSuccessDialog = ref(null);
const disconnectErrorDialog = ref(null);
const staticIpInProgress = useStore($staticIpInProgress);
onBeforeMount(() => {
  onSuccessFx.use(() => { disconnectSuccessDialog.value.open() });
  onErrorFx.use(() => { disconnectErrorDialog.value.open });
});
</script>