import { createEffect, createStore } from 'effector';

import { createRequestFx } from '../app/http/Http.js';

export const getUserInfoFx = createEffect(data => createRequestFx({
  data,
  config: {
    path: 'be_clientinfo',
    method: 'get'
  }
}));

export const $userInfo = createStore(null);

$userInfo.on(getUserInfoFx.doneData, (_, response) => response.data.data);
