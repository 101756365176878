<template>
<section>
  <statistic-control v-model:value="date" @submit="submit" :loading="loading" />
  <b-table v-if="(items.length > 0 || isLoaded) && !loading"
           class="internet-statistic-traffic_scrollable mt-16"
           :is-empty-result="isLoaded && items.length === 0"
           :fields="fields"
           :items="items" />
  <b-pagination v-if="items.length > 0 && isLoaded && !loading"
                :pagination="pagination"
                :pagination-api="paginationApi" />
</section>
</template>

<script setup>
import { useStore } from 'effector-vue/composition';
import { computed, onBeforeUnmount } from 'vue';

import { onBeforeRouteUpdate } from 'vue-router';

import BPagination from '../../services/BPagination.vue';
import BTable from '../../services/BTable.vue';

import StatisticControl from '../../statistic/StatisticControll.vue';

import { $dateRange,
         $isLoaded, $pagination,
         $trafficStat, beNumTrafficFx, getBeNumTraffic,
         setDateRange, paginationApi, resetTrafficStat } from './BeNumTraffic.js';

const items = useStore($trafficStat);
const isLoaded = useStore($isLoaded);
const fields = [
  { title: 'Начало сессии', key: 'startdate' },
  { title: 'Окончание сессии', key: 'enddate' },
  { title: 'IP-адрес', key: 'ip' },
  { title: 'Длительность сессии', key: 'duration' },
  { title: 'Принято', key: 'input' },
  { title: 'Передано', key: 'output' }
];
const loading = useStore(beNumTrafficFx.pending);
const pagination = useStore($pagination);

const dateRangeStore = useStore($dateRange);
const date = computed({
  get() {
    return dateRangeStore.value;
  },
  set(value) {
    setDateRange(value);
  }
});
onBeforeRouteUpdate(() => {
  resetTrafficStat();
});
onBeforeUnmount(() => {
  resetTrafficStat();
});
const submit = () => {
  getBeNumTraffic();
};
</script>

<style lang="scss">
.internet-statistic-traffic {
  &_scrollable {
    max-height: 37vh;
    overflow-y: auto;
  }
}
</style>
