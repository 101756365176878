<template>
<section>
  <statistic-control v-model:value="date" @submit="submit" :loading="loading" />
  <empty-result v-if="isLoaded && items.length === 0 && !loading" />
  <template :key="index" v-for="(item,index) in items">
    <payments-table class="mt-24" :type="item.key" :items="item.items" />
  </template>
</section>
</template>

<script setup>

import { useStore } from 'effector-vue/composition';

import { computed, onBeforeMount, onBeforeUnmount } from 'vue';

import { onBeforeRouteUpdate, useRoute } from 'vue-router';

import { $dateRange,
         $isLoaded,
         $paymentsStat,
         beNumPaymentsFx, resetPaymentsStat,
         setDateRange,
         setServiceParams } from './BeNumPayments.js';
import EmptyResult from './EmptyResult.vue';
import PaymentsTable from './PaymentsTable.vue';
import StatisticControl from './StatisticControll.vue';

const items = useStore($paymentsStat);

const dateRangeStore = useStore($dateRange);
const date = computed({
  get() {
    return dateRangeStore.value;
  },
  set(value) {
    setDateRange(value);
  }
});
const submit = () => {
  beNumPaymentsFx();
};
const isLoaded = useStore($isLoaded);
const loading = useStore(beNumPaymentsFx.pending);

const route = useRoute();
onBeforeMount(() => {
  setServiceParams(route.params);
});
onBeforeRouteUpdate(() => {
  resetPaymentsStat();
});
onBeforeUnmount(() => {
  resetPaymentsStat();
});
</script>
