import * as yup from 'yup';

yup.addMethod(yup.number, 'len', function (numberLength, message) {
  return this.transform(value => Number.isNaN(value) ? undefined : value).test((value, ctx) => {
    const { createError } = ctx;
    return (value && value.toString().length === numberLength) || createError({ message });
  });
});

export { yup };
