<template>
<section>
  <b-text class="mt-16" type="h4">Дополнительные услуги</b-text>
  <phone-select-service class="mt-16" />
  <tab-nav class="mt-8 mb-26" :links="nav" />
  <router-view />
</section>
</template>

<script setup>
import { BText } from 'uikit';

import TabNav from '../tab-nav/TabNav.vue';

import PhoneSelectService from './PhoneSelectService.vue';
import { PHONE_BLOCK_PAGE, PHONE_SERVICE_TABLE_ROUTE, PHONE_TARIFF_ROUTE } from './RouterConst.js';

const nav = [
  { text: 'Услуги', to: { name: PHONE_SERVICE_TABLE_ROUTE } },
  { text: 'Смена тарифного плана', to: { name: PHONE_TARIFF_ROUTE } },
  { text: 'Временная блокировка', to: { name:  PHONE_BLOCK_PAGE } }
];
</script>