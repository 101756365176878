<template>
<b-label type="outline"
         v-bind="attrs" />
</template>

<script setup>
import { BLabel } from 'uikit';
import { computed } from 'vue';
const props = defineProps({
  status: {
    required: true,
    validator: value => {
      if (value === null) return true;
      return ['number', 'string'].includes(typeof value);
    }
  }
});
const activeAttrs = {
  text: 'Активен',
  icon: 'check_circled',
  status: 'success'
};
const blockAttrs = {
  text: 'Заблокирован',
  icon: 'cancel',
  status: 'error'
};
const notConnectedAttrs = {
  text: 'Не подключен',
  icon: 'question_circled',
  status: 'neutral'
};
const attrs = computed(() => {
  if (props.status == '1') return activeAttrs;
  if (props.status == '2') return blockAttrs;
  return notConnectedAttrs;
});
</script>
