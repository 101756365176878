<template>
<section>
  <b-table v-model="selectedTariff"
           class="tariff-page_scrollable mt-16"
           :fields="fields"
           show-select
           item-key="cobplan"
           :items="items">
    <template #nmbplan="{item, field}">
      <b v-if="item.cobplan === selectedService.cobplan">
        {{ item[field.key] }}
      </b>
      <template v-else>
        {{ item[field.key] }}
      </template>
    </template>
  </b-table>
  <section class="mt-16 flex-center-y">
    <b-button @click="connect"
              :disabled="!selectedTariff || loading"
              class="mr-16"
              type="contained"
              size="small">
      Сменить тарифный план
    </b-button>
    <b-button @click="goToBeeline"
              type="outlined"
              size="small">
      Подробнее о тарифных планах
    </b-button>
  </section>
  <tariff-connect-dialog ref="connectDialog" />
  <tariff-connect-success-dialog ref="connectSuccessDialog" />
  <tariff-connect-error-dialog ref="connectErrorDialog" />
</section>
</template>

<script setup>

import { useStore, useVModel } from 'effector-vue/composition.mjs';
import { BButton } from 'uikit';
import { onBeforeMount, ref } from 'vue';
import { useRoute } from 'vue-router';

import BTable from '../services/BTable.vue';

import { $loadingBeChangeBpFx, onErrorFx, onSuccessFx } from './BeChangeBp.js';
import { $cobplan, $plansListWithActiveTariff, $service, setServiceParams } from './Tariff.js';
import TariffConnectDialog from './TariffConnectDialog.vue';
import TariffConnectErrorDialog from './TariffConnectErrorDialog.vue';
import TariffConnectSuccessDialog from './TariffConnectSuccessDialog.vue';

const fields = [
  { title: 'Тарифный план', key: 'nmbplan' },
  { title: 'Описание', key: 'description' }
];
const selectedTariff = useVModel($cobplan);
const selectedService = useStore($service);
const items = useStore($plansListWithActiveTariff);

const goToBeeline =  () => {
  window.open(' https://beeline.ru/customers/products/', '_blank');
};

const connectDialog = ref(null);
const loading = useStore($loadingBeChangeBpFx);
const connect = () => {
  connectDialog.value.open();
};
const route = useRoute();

const connectSuccessDialog = ref(null);
const success = () => {
  connectSuccessDialog.value.open();
};
const connectErrorDialog = ref(null);
const error = () => {
  connectErrorDialog.value.open();
};
onBeforeMount(() => {
  setServiceParams(route.params);
  onSuccessFx.use(success);
  onErrorFx.use(error);
});
</script>

<style lang="scss">
.tariff-page {
  &_scrollable {
    max-height: 37vh;
    overflow-y: auto;
  }
}
</style>
