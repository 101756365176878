<template>
<div class="h4 mt-26 mb-22">Статистика</div>
<phone-select-service />
<tab-nav :links="links" class="mt-8" />
<router-view class="mt-32" />
</template>

<script setup>

import TabNav from '../../tab-nav/TabNav.vue';
import PhoneSelectService from '../PhoneSelectService.vue';
import { PHONE_STATISTIC_BILL,
         PHONE_STATISTIC_DET_CALLS,
         PHONE_STATISTIC_PAYMENTS,
         PHONE_STATISTIC_SERVICE } from '../RouterConst.js';

const links = [
  { text: 'Статистика платежей', to: { name: PHONE_STATISTIC_PAYMENTS } },
  { text: 'Статистика по выставленным счетам', to: { name: PHONE_STATISTIC_BILL } },
  { text: 'Статистика по услугам', to: { name: PHONE_STATISTIC_SERVICE } },
  { text: 'Статистика звонков', to: { name: PHONE_STATISTIC_DET_CALLS } }
];

</script>