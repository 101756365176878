import { attach, combine, createEvent, createStore, sample } from 'effector';

import { beNuminfo, getServicesFx, PHONE_TYPE } from '../services/Services.js';
import { servicesSum } from '../services/ServicesSum.js';

export const $phoneService = createStore(null);

export const setPhoneService = createEvent();

$phoneService.on(setPhoneService, (_, service) => service);

export const resetPhoneService = createEvent();
$phoneService.reset(resetPhoneService);

export const getPhoneServicesWithParamsFx = attach({
  effect: beNuminfo,
  mapParams: params => ({ ...params, v_coc: PHONE_TYPE })
});

export const $phoneListWithParams = createStore([]);

$phoneListWithParams.on(getPhoneServicesWithParamsFx.doneData, (_, response) => {
  const services = response.data.data.services;
  return servicesSum(services);
});
export const $phoneServiceWithParams = combine({
  service: $phoneService,
  list: $phoneListWithParams
}).map(({ service, list }) => {
  if (service === null) return null;
  const { nmbillgroup, conum } = service;
  return list.find(item => item.nmbillgroup === nmbillgroup && item.conum === conum);
});

sample({
  clock: getServicesFx,
  target: resetPhoneService
});
