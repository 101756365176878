<template>
<section>
  <b-text class="mt-16" type="h4">Дополнительные возможности</b-text>
  <b-text class="mt-16" type="h5">Смена тарифного плана</b-text>
  <b-text class="mt-16" type="body2">
    Чтобы изменить тарифный план обратитесь в офис Билайн
    <br />
    в вашем городе
  </b-text>

  <b-button @click="goToBeeline"
            class="mt-16"
            type="outlined"
            size="small">
    Подробнее о тарифных планах
  </b-button>
</section>
</template>

<script setup>
import { BText, BButton } from 'uikit';

const goToBeeline =  () => {
  window.open(' https://beeline.ru/customers/products/', '_blank');
};
</script>