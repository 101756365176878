import { createEffect, createStore, sample } from 'effector';

import { loginFx } from '../../login/Login.js';

export const $token = createStore(null);

$token.on(loginFx.doneData, (_, response) => {
  const token = response.data?.data?.token;
  return token;
});

export const restoreToken = createEffect(() => {
  try {
    const token = localStorage.getItem('token');
    if (token) {
      return token;
    }
    console.warn('Token is empty');
  } catch (error) {
    console.error(error);
  }
});

$token.on(restoreToken.doneData, (_, token) => token);

const saveTokenFx = createEffect(token => {
  localStorage.setItem('token', token);
});

sample({
  clock: loginFx.doneData,
  target: saveTokenFx,
  fn: (_, response) =>  response.data?.data?.token
});
