<template>
<b-dialog ref="modal" teleport="#dialogs">
  <div class="widget-modal-item">
    <b-icon name="cancel" class="text-red widget-modal-item__icon" />
    <div class="widget-modal-item__content')">
      <slot></slot>
    </div>
    <b-button @click="doAction">{{ cancelText }}</b-button>
  </div>
</b-dialog>
</template>

<script setup>
import { BDialog, BIcon, BButton } from 'uikit';
import { ref } from 'vue';

const modal = ref(null);
const emit = defineEmits({
  result: () => true
});

const close = () => modal.value.close();
const open = () => modal.value.open();

const doAction = () => {
  emit('result');
  close();
};

defineProps({
  cancelText: { type: String, default: 'Вернуться назад' }
});
defineExpose({ open, close });
</script>

<style scoped lang="scss" src="./WidgetModal.scss" />
