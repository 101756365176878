<template>
<chips-nav :links="nav" />
<router-view />
</template>

<script setup>

import ChipsNav from '../chips-nav/ChipsNav.vue';

import { INTERNET_INFO_ROUTE, INTERNET_SERVICE_SETTING_ROUTE, INTERNET_STATISTIC_ROUTE } from './RouterConst.js';

const nav = [
  { text: 'Информация о сервисе', to: { name: INTERNET_INFO_ROUTE } },
  { text: 'Пополнение баланса', to: { name: 'user-balance' } },
  { text: 'Управление услугами', to: { name: INTERNET_SERVICE_SETTING_ROUTE } },
  { text: 'Статистика', to: { name: INTERNET_STATISTIC_ROUTE } }
];

</script>