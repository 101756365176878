<template>
<section>
  <b-text type="h5" class="mt-26">Временная блокировка</b-text>

  <b-text class="mt-10 phone-block-content" type="body2">
    В данном разделе вы можете временно заблокировать домашний телефон.
    <br />
    <br />
    Эта опция полезна в том случае, если вам нужно на какое-то время отключить телефон, но для вас важно сохранить номер.
    <br />
    <br />
    На период блокировки номер телефона резервируется за вами, и по окончанию срока действия услуги вы сможете пользоваться прежним номером.
    <br />
    <br />
    Вы можете заблокировать домашний телефон на любой срок.
    <br />
    <br />
    Для этого необходимо внести на счет сумму в размере абонентской платы за весь период отключения телефона в соответствии с параметрами вашего тарифного плана
    <br />
    <br />
    Разовая плата за установку «Добровольной блокировки» составляет 100 рублей.
  </b-text>

  <b-button v-if="phoneService && phoneService.status === 1"
            @click="block"
            type="outlined"
            size="medium"
            class="mt-10">
    Включить временную блокировку услуги
  </b-button>
  <b-button v-if="phoneService && phoneService.status === 2"
            @click="unBlock"
            type="outlined"
            size="medium"
            class="mt-10">
    Отключить временную блокировку услуги
  </b-button>
  <phone-block-dialog ref="blockDialog" />
  <phone-block-error-dialog ref="blockErrorDialog" />
  <phone-block-success-dialog ref="blockSuccessDialog" />

  <phone-un-block-dialog ref="unBlockDialog" />
  <phone-un-block-error-dialog ref="unBlockErrorDialog" />
  <phone-un-block-success-dialog ref="unBlockSuccessDialog" />
</section>
</template>

<script setup>
import { useStore } from 'effector-vue/composition';
import { BText, BButton } from 'uikit';

import { onBeforeMount, ref } from 'vue';

import { $phoneService } from '../PhoneService.js';

import PhoneUnBlockDialog from '../unblock/PhoneUnBlockDialog.vue';

import PhoneUnBlockErrorDialog from '../unblock/PhoneUnBlockErrorDialog.vue';
import PhoneUnBlockSuccessDialog from '../unblock/PhoneUnBlockSuccessDialog.vue';

import { onUnBlockErrorFx, onUnBlockSuccessFx } from '../unblock/UnBlock.js';

import { onErrorFx, onSuccessFx } from './Block.js';
import PhoneBlockDialog from './PhoneBlockDialog.vue';
import PhoneBlockErrorDialog from './PhoneBlockErrorDialog.vue';
import PhoneBlockSuccessDialog from './PhoneBlockSuccessDialog.vue';

const blockDialog = ref(null);
const unBlockDialog = ref(null);
const block = () => {
  blockDialog.value.open();
};
const unBlock = () => {
  unBlockDialog.value.open();
};
const blockErrorDialog = ref(null);
const blockSuccessDialog = ref(null);
const unBlockSuccessDialog = ref(null);
const unBlockErrorDialog = ref(null);
const phoneService = useStore($phoneService);
onBeforeMount(() => {
  onErrorFx.use(() => {
    blockErrorDialog.value.open();
  });
  onSuccessFx.use(() => {
    blockSuccessDialog.value.open();
  });
  onUnBlockErrorFx.use(() => {
    unBlockErrorDialog.value.open();
  });
  onUnBlockSuccessFx.use(() => {
    unBlockSuccessDialog.value.open();
  });
});
</script>

<style lang="scss">

.phone-block-content{
  width: 512px;
}
</style>
