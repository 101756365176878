import { attach, combine, createEvent, createStore, sample } from 'effector';

import { beNuminfo, INTERNET_TYPE, getServicesFx } from '../services/Services.js';
import { servicesSum } from '../services/ServicesSum.js';

export const $internetService = createStore(null);

export const setInternetService = createEvent();

$internetService.on(setInternetService, (_, service) => service);

export const getInternetServicesWithParams = attach({
  effect: beNuminfo,
  mapParams: params => ({ ...params, v_coc: INTERNET_TYPE })
});

export const $internetListWithParams = createStore([]);

export const resetInternetService = createEvent();
$internetService.reset(resetInternetService);

$internetListWithParams.on(getInternetServicesWithParams.doneData, (_, response) => {
  const services = response.data.data.services;
  return servicesSum(services);
});

export const $internetServiceWithParams = combine({
  service: $internetService,
  list: $internetListWithParams
}).map(({ service, list }) => {
  if (service === null) return null;
  const { nmbillgroup, conum } = service;
  return list.find(item => item.nmbillgroup === nmbillgroup && item.conum === conum);
});

sample({
  clock: getServicesFx,
  target: resetInternetService
});
