import { format, parseISO } from 'date-fns';
import { attach, combine, createEffect, createEvent, createStore, forward, sample } from 'effector';

import { createRequestFx } from '../app/http/Http.js';
import { isLoaded } from '../app/IsLoaded.js';
import { paginationFabric } from '../app/Pagination.js';
import { serviceByRouteParamsFabric } from '../services/ServiceByRouteParams.js';

import { dateRangeFabric } from './Date.js';

const getBeNumBillOriginalFx = createEffect(data => createRequestFx({
  data,
  config: {
    path: 'be_num_bill',
    method: 'get'
  }
}));

const { $service: $serviceBeNumBill, setServiceParams } = serviceByRouteParamsFabric();

const { $dateRange, setDateRange, $outputDateRange } = dateRangeFabric({
  start: null,
  end: null
});

const $data = combine({
  v_cobillgroup: $serviceBeNumBill.map(service => service ? service.cobillgroup : null),
  $outputDateRange
}).map(({ v_cobillgroup, $outputDateRange }) => ({
  v_cobillgroup,
  ...$outputDateRange
}));

const getBeNumBillWithDataFx =  attach({
  effect: getBeNumBillOriginalFx,
  source: { $data },
  mapParams: (params, { $data }) => ({ ...params, ...$data })
});

const { effect: getBeNumBillFx, $pagination, api: paginationApi } = paginationFabric(getBeNumBillWithDataFx);

export const $billStat = createStore([]);
$billStat.on(getBeNumBillFx.doneData, (_, response) => response.data.data.map(item => ({
  cobill: item.cobill,
  operdate: format(parseISO(item.operdate), 'dd.MM.yyyy HH:mm'),
  paydate: format(parseISO(item.paydate), 'dd.MM.yyyy HH:mm'),
  amount: `${item.amount}`
})));

export const $isLoaded = isLoaded(getBeNumBillFx);

export const $cobill = createStore('');

export const getPdfIntFx = createEffect(data => createRequestFx({
  data,
  config: {
    path: 'get_pdf_int',
    method: 'get',
    responseType: 'blob'
  }
}));
export const getPdfInt = createEvent();

sample({
  clock: getPdfInt,
  source: $cobill,
  // eslint-disable-next-line camelcase
  fn: v_cobill => ({ v_cobill }),
  target: getPdfIntFx
});

export const getPdfTelFx = createEffect(data => createRequestFx({
  data,
  config: {
    path: 'get_pdf_tel',
    method: 'get',
    responseType: 'blob'
  }
}));

export const getPdfTel = createEvent();
sample({
  clock: getPdfTel,
  source: $cobill,
  // eslint-disable-next-line camelcase
  fn: v_cobill => ({ v_cobill }),
  target: getPdfTelFx
});

export const getPdfKtvFx = createEffect(data => createRequestFx({
  data,
  config: {
    path: 'get_pdf_ktv',
    method: 'get',
    responseType: 'blob'
  }
}));
export const getPdfKtv = createEvent();
sample({
  clock: getPdfKtv,
  source: { $cobill, conumvolume: $serviceBeNumBill.map(service => service ? service.conumvolume : null) },
  fn: ({ $cobill, conumvolume }) => ({ v_cobill: $cobill, v_conumvolume: conumvolume }),
  target: getPdfKtvFx
});

export const $loadingPdf = combine([getPdfIntFx.pending, getPdfTelFx.pending, getPdfKtvFx.pending], loaders => loaders.some(loading => loading));
export const $loadingBeNumBillFx = getBeNumBillFx.pending;

const downloadFx = createEffect(response => {
  const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
  window.open(url);
});

forward({
  from: [getPdfIntFx.doneData, getPdfTelFx.doneData, getPdfKtvFx.doneData],
  to: downloadFx
});
export const resetBillStat = createEvent();
$billStat.reset(resetBillStat);
$isLoaded.reset(resetBillStat);
$dateRange.reset(resetBillStat);
$cobill.reset(resetBillStat);

forward({
  from: $serviceBeNumBill,
  to: resetBillStat
});

export const getBeNumBill = createEvent();

sample({
  clock: getBeNumBill,
  target: [paginationApi.resetPagination, getBeNumBillFx]
});

export {
  $dateRange, setDateRange, $pagination, paginationApi, getBeNumBillFx, setServiceParams
};
