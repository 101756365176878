import { createEffect, createStore, forward } from 'effector';

import { createRequestFx } from '../app/http/Http.js';
import { isLoginError } from '../app/is-error/index.js';

import { router } from '../app/Router.js';

export const $user = createStore(null);
export const loginFx = createEffect(data => createRequestFx({
  data,
  config: { path: 'be_authorization', method: 'post' }
}));
export const { $errorMessage: $loginErrorMessage } = isLoginError(loginFx);
$user.on(loginFx.doneData, (state, response) => {
  if (response.data.success) {
    return response.data.data;
  }
  return state;
});

const redirectFx = createEffect(() => {
  router.push('/');
});

forward({
  from: loginFx.doneData,
  to: redirectFx
});
