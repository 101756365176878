<template>
<section>
  <b-text class="mt-16" type="h4">Информация о сервисе</b-text>
  <phone-select-service class="mt-16" />
  <service-info @block="goToBlock"
                class="mt-16"
                :loading="loadingPhoneParams"
                :service="phoneServiceWithParams || {}"
                :phone="true" />
  <b-text class="mt-32" type="h4">Подключенные услуги</b-text>
  <service-tariff v-if="phoneService" :link="PHONE_TARIFF_ROUTE" class="mt-16" :service="phoneService" />
</section>
</template>

<script setup>
import { useStore } from 'effector-vue/composition.mjs';
import { BText } from 'uikit';

import { useRouter } from 'vue-router';

import ServiceInfo from '../services/ServiceInfo.vue';
import ServiceTariff from '../services/ServiceTariff.vue';

import PhoneSelectService from './PhoneSelectService.vue';
import { $phoneService, $phoneServiceWithParams, getPhoneServicesWithParamsFx } from './PhoneService.js';
import { PHONE_BLOCK_PAGE, PHONE_TARIFF_ROUTE } from './RouterConst.js';
const phoneService = useStore($phoneService);
const phoneServiceWithParams = useStore($phoneServiceWithParams);
const router = useRouter();
const goToBlock = () => {
  router.push({ name: PHONE_BLOCK_PAGE });
};
const loadingPhoneParams = useStore(getPhoneServicesWithParamsFx.pending);
</script>