import { combine, createEffect, createEvent, sample } from 'effector';

import { createRequestFx } from '../../app/http/Http.js';
import { $coclient } from '../../user/User.js';
import { $internetService } from '../InternetService.js';

const beStatIpOffFx = createEffect(data => createRequestFx({
  data,
  config: {
    path: 'be_statip_off',
    method: 'put'
  }
}));

const $data = combine([
  $coclient,
  $internetService
// eslint-disable-next-line camelcase
]).map(([v_userid, service]) => ({
  v_userid,
  v_conumvolume: service ? service.conumvolume : null
}));

export const beStatIpOff = createEvent();
sample({
  clock: beStatIpOff,
  source: $data,
  target: beStatIpOffFx
});

export const onSuccessFx = createEffect();
sample({
  clock: beStatIpOffFx.doneData,
  target: onSuccessFx
});

export const onErrorFx = createEffect();
sample({
  clock: beStatIpOffFx.failData,
  target: onErrorFx
});
