<template>
<div ref="dialog" id="dialogs"></div>
</template>

<script setup>

import { onBeforeUnmount, onMounted, ref } from 'vue';

const dialog = ref(null);

const withoutScrollClass = 'without-scroll';
const sideModalClass = 'b-dialog__wrapper';

const observer = ref(null);
const observerCallback = mutationsList => {
  for (const mutation of mutationsList) {
    if (mutation.type === 'childList') {
      const nodes = mutation.target.childNodes;
      const existSideModal = [...nodes].some(node => node.classList.contains(sideModalClass));
      document.body.classList.toggle(withoutScrollClass, existSideModal);
    }
  }
};
const portalObserver = () => {
  const config = {
    childList: true
  };
  observer.value = new MutationObserver(observerCallback);
  observer.value.observe(dialog.value, config);
};
onMounted(() => {
  portalObserver();
});
onBeforeUnmount(() => {
  if (observer.value) {
    document.body.classList.toggle(withoutScrollClass, false);
    observer.value.disconnect();
  }
});
</script>

<style lang="scss">
.without-scroll{
  height: 100vh;
  overflow-y: hidden;
}

</style>