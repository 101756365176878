<template>
<section v-if="links.length > 0" class="chips-nav">
  <template :key="link.text" v-for="link in links">
    <b-chips-link class="chips-nav__elem" :text="link.text" :to="link.to" />
  </template>
</section>
</template>

<script>
export default {};
</script>

<script setup>
import { BChipsLink } from 'uikit';

defineProps({
  links: { type: Array, default: () => [] }
});
</script>

<style lang="scss">
.chips-nav{
  padding-top: 10px;
  padding-bottom: 10px;

  &__elem{
    margin-right: 8px;
  }
}
</style>