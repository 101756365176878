<template>
<section v-if="staticIpData && isEnabled" class="bordered p-22 pl-24 pr-16 flex-center-y">
  <b-text class="mr-16" type="h5">Статический IP-адрес </b-text>
  <b-label type="outline" v-bind="activeAttrs" />
</section>
</template>

<script setup>
import { useStore } from 'effector-vue/composition.mjs';
import { BText, BLabel } from 'uikit';
import { computed } from 'vue';

import { $internetStaticIp } from './StaticIp.js';

const staticIpData = useStore($internetStaticIp);

const isEnabled = computed(() => {
  if (staticIpData.value === null) return false;
  return staticIpData.value.v_enabled === 1;
});
const activeAttrs = {
  text: 'Активен',
  icon: 'check_circled',
  status: 'success'
};

</script>