import StatisticBillPage from '../statistic/StatisticBillPage.vue';
import StatisticPaymentsPage from '../statistic/StatisticPaymentsPage.vue';
import StatisticServicePage from '../statistic/StatisticServicePage.vue';

import TariffPage from '../tariff/TariffPage.vue';

import PhoneBlockPage from './block/PhoneBlockPage.vue';
import PhoneInfoPage from './PhoneInfoPage.vue';
import PhonePage from './PhonePage.vue';
import PhoneServicePage from './PhoneServicePage.vue';
import PhoneServiceSettingPage from './PhoneServiceSettingPage.vue';
import PhoneServiceTablePage from './PhoneServicesTablePage.vue';
import * as phoneRouter from './RouterConst.js';
import PhoneStatisticDetCallsPage from './statistic/PhoneStatisticDetCallsPage.vue';
import PhoneStatistic from './statistic/PhoneStatisticPage.vue';

export default [
  {
    name: phoneRouter.PHONE_ROUTE,
    path: '/phone',
    component: PhonePage,
    children: [
      {
        name: phoneRouter.PHONE_SERVICE_ROUTE,
        path: '/phone/:nmbillgroup/:conum',
        component: PhoneServicePage,
        children: [
          {
            name: phoneRouter.PHONE_INFO_ROUTE,
            path: 'info',
            component: PhoneInfoPage
          },
          {
            name: phoneRouter.PHONE_STATISTIC_ROUTE,
            path: 'statistic',
            component: PhoneStatistic,
            redirect: () => ({ name: phoneRouter.PHONE_STATISTIC_PAYMENTS }),
            children: [
              {
                name: phoneRouter.PHONE_STATISTIC_PAYMENTS,
                path: 'payments',
                component: StatisticPaymentsPage
              },
              {
                name: phoneRouter.PHONE_STATISTIC_BILL,
                path: 'bill',
                component: StatisticBillPage
              },
              {
                name: phoneRouter.PHONE_STATISTIC_SERVICE,
                path: 'service',
                component: StatisticServicePage
              },
              {
                name: phoneRouter.PHONE_STATISTIC_DET_CALLS,
                path: 'det-calls',
                component: PhoneStatisticDetCallsPage
              }
            ]
          },
          {
            name: phoneRouter.PHONE_SERVICE_SETTING_ROUTE,
            path: 'setting',
            component: PhoneServiceSettingPage,
            redirect: () => ({ name: phoneRouter.PHONE_SERVICE_TABLE_ROUTE }),
            children: [
              {
                name: phoneRouter.PHONE_TARIFF_ROUTE,
                path: 'tariff',
                component: TariffPage
              },
              {
                name: phoneRouter.PHONE_SERVICE_TABLE_ROUTE,
                path: 'services',
                component: PhoneServiceTablePage
              },
              {
                name: phoneRouter.PHONE_BLOCK_PAGE,
                path: 'block',
                component: PhoneBlockPage
              }
            ]
          }
        ]
      }
    ]
  }

];