<template>
<chips-nav :links="nav" />
<router-view />
</template>

<script setup>
import ChipsNav from '../chips-nav/ChipsNav.vue';

import { PHONE_INFO_ROUTE, PHONE_SERVICE_SETTING_ROUTE, PHONE_STATISTIC_ROUTE } from './RouterConst.js';

const nav = [
  { text: 'Информация о сервисе', to: { name: PHONE_INFO_ROUTE } },
  { text: 'Пополнение баланса', to: { name: 'user-balance' } },
  { text: 'Управление услугами', to: { name: PHONE_SERVICE_SETTING_ROUTE } },
  { text: 'Статистика', to: { name: PHONE_STATISTIC_ROUTE } }
];

</script>