<template>
<section>
  <statistic-control v-model:value="date" @submit="submit" :loading="loading" />
  <b-table v-if="items.length > 0 && !loading"
           class="statistic-service_scrollable t-16"
           :fields="fields"
           :items="items" />

  <empty-result v-if="isLoaded && items.length === 0 && !loading" />

  <b-pagination v-if="items.length > 0 && isLoaded && !loading"
                :pagination="pagination"
                :pagination-api="paginationApi" />
</section>
</template>

<script setup>

import { useStore } from 'effector-vue/composition';
import { computed, onBeforeMount, onBeforeUnmount } from 'vue';

import { onBeforeRouteUpdate, useRoute } from 'vue-router';

import BPagination from '../services/BPagination.vue';
import BTable from '../services/BTable.vue';

import { $dateRange,
         $isLoaded,
         $pagination,
         $serviceStat,
         beNumServiceFx,
         getBeNumService,
         resetServiceStat,
         setDateRange,
         paginationApi,
         setServiceParams } from './BeNumService.js';
import EmptyResult from './EmptyResult.vue';
import StatisticControl from './StatisticControll.vue';

const dateRangeStore = useStore($dateRange);
const date = computed({
  get() {
    return dateRangeStore.value;
  },
  set(value) {
    setDateRange(value);
  }
});
const pagination = useStore($pagination);
const items = useStore($serviceStat);
const fields = [
  { title: 'Дата', key: 'startdate' },
  { title: 'Событие', key: 'numevent' }
];

const submit = () => {
  getBeNumService();
};
const isLoaded = useStore($isLoaded);
const loading = useStore(beNumServiceFx.pending);

onBeforeRouteUpdate(() => {
  resetServiceStat();
});
onBeforeUnmount(() => {
  resetServiceStat();
});
const route = useRoute();
onBeforeMount(() => {
  setServiceParams(route.params);
});
</script>

<style lang="scss">
.statistic-service {
  &_scrollable {
    max-height: 37vh;
    overflow-y: auto;
  }
}
</style>
