<template>
<b-dialog-confirm ref="dialog"
                  @ok="okEvent"
                  ok-text="Понятно">
  <template #title>
    <div class="flex-center-y align-items-flex-start">
      <b-icon name="check_circled" theme="green" class="mr-8" />
      Блокировка доступа к услуге будет включена с {{ nextDay }}. Блокировка будет действовать в течение 90 дней.
    </div>
  </template>
  <template #default>
    Вы сможете отменить блокировку самостоятельно в Личном кабинете. Если вы не отмените блокировку через 90 дней, то временная блокировка автоматически выключится, и списание денежных средств возобновится.
  </template>
</b-dialog-confirm>
</template>

<script setup>
import { addDays, format } from 'date-fns';
import { BDialogConfirm, BIcon } from 'uikit';

import { ref } from 'vue';
const nextDay = format(addDays(new Date(), 1), 'dd.MM.yyyy');
const dialog = ref(null);
const okEvent = () => close();
const open = () => dialog.value.open();
const close = () => dialog.value.close();
defineExpose({ open, close });
</script>