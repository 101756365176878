import { format, parseISO } from 'date-fns';
import { attach, combine, createEffect, createEvent, createStore, sample } from 'effector';

import { createRequestFx } from '../app/http/Http.js';
import { isLoaded } from '../app/IsLoaded.js';
import { paginationFabric } from '../app/Pagination.js';
import { serviceByRouteParamsFabric } from '../services/ServiceByRouteParams.js';

import { dateRangeFabric } from './Date.js';

const beNumServiceOriginalFx =  createEffect(data => createRequestFx({
  data,
  config: {
    path: 'be_num_service',
    method: 'get'
  }
}));

const { $service, setServiceParams } = serviceByRouteParamsFabric();

const { $dateRange, setDateRange, $outputDateRange } = dateRangeFabric({
  start: null,
  end: null
});

const $data = combine({
  v_conumvolume: $service.map(service => service ? service.conumvolume : null),
  $outputDateRange
}).map(({ v_conumvolume, $outputDateRange }) => ({
  v_conumvolume,
  ...$outputDateRange
}));

const beNumServiceWithDataFx =  attach({
  effect: beNumServiceOriginalFx,
  source: { $data },
  mapParams: (params, { $data }) => ({ ...params, ...$data })
});

const { effect: beNumServiceFx, $pagination, api: paginationApi } = paginationFabric(beNumServiceWithDataFx);
const $serviceStat = createStore([]);

$serviceStat.on(beNumServiceFx.doneData, (_, response) => response.data.data.map(item => ({
  startdate: format(parseISO(item.startdate), 'dd.MM.yyyy HH:mm'),
  numevent: item.numevent
})));

const $isLoaded = isLoaded(beNumServiceFx);
const getBeNumService = createEvent();

sample({
  clock: getBeNumService,
  target: [paginationApi.resetPagination, beNumServiceFx]
});

const resetServiceStat = createEvent();
$serviceStat.reset(resetServiceStat);
$isLoaded.reset(resetServiceStat);
$dateRange.reset(resetServiceStat);
export {
  $dateRange,
  $isLoaded,
  $pagination,
  $serviceStat,
  beNumServiceFx,
  getBeNumService,
  paginationApi,
  resetServiceStat,
  setDateRange,
  setServiceParams
};
