<template>
<section>
  <div @click="changeView" class="flex-center-y mb-16 hovered">
    <div class="h5 mr-16">Тип платежа: {{ type }} </div>
    <b-icon :name="iconName" />
  </div>
  <b-table class="mt-16 payments-table_scrollable" :fields="fields" :items="currentItems">
    <template #total>
      <tr>
        <td class="b-table__td ">
          <div class="subtitle3">Итого</div>
        </td>
        <td></td>
        <td></td>
        <td class="b-table__td ">
          <div class="subtitle3">{{ sum }} руб</div>
        </td>
      </tr>
    </template>
  </b-table>
</section>
</template>

<script setup>
import { BIcon } from 'uikit';
import { computed, ref } from 'vue';

import BTable from '../services/BTable.vue';

const props = defineProps({
  type: { type: String, default: '' },
  items: { type: Array, default: () => [] }
});
const visible = ref(true);
const iconName = computed(() => visible.value ? 'nav_arrow_up' : 'nav_arrow_down');
const fields = [
  { title: 'Номер', key: 'documentnumber', formatter: value => value ? value : '-' },
  { title: 'Дата платежа', key: 'operdate' },
  { title: 'Тип платежа', key: 'opername' },
  { title: 'Сумма платежа с НДС', key: 'paysum', formatter: value => `${value} руб` }
];
const sum = computed(() => props.items.reduce((buf, value) => buf + Number.parseFloat(value.paysum), 0));
const changeView = () => {
  visible.value = !visible.value;
};
const currentItems = computed(() => visible.value ? props.items : []);
</script>

<style lang="scss">
.payments-table {
  &_scrollable {
    max-height: 37vh;
    overflow-y: auto;
  }
}
</style>
