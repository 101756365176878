<template>
<section class="user-change-password">
  <div class="h4 color-active ">Сменить пароль</div>
  <b-inline-alert class="mt-16" status="warning">
    Обращаем внимание, что после смены пароля вам будет необходимо внести изменения в настройки вашего
    Интернет-соединения, а также в дальнейшем использовать новый пароль для входа в Личный кабинет
  </b-inline-alert>

  <div class="user-change-password__form bordered mt-16">
    <div class="h5">Введите старый пароль</div>
    <b-text-field v-model:value="oldpassword"
                  :error="oldpasswordError.length > 0"
                  :hint="oldpasswordError[0] || ''"
                  class="mt-12"
                  :disabled="loading"
                  size="large"
                  :trigger-password="true"
                  type="password"
                  label="Старый пароль" />
    <div class="h5 mt-24">Введите новый пароль</div>
    <b-text-field v-model:value="newpassword"
                  :error="newpasswordError.length > 0"
                  :hint="newpasswordError[0] || '' "
                  class="mt-12"
                  :disabled="loading"
                  size="large"
                  :trigger-password="true"
                  type="password"
                  label="Новый пароль" />
    <b-text-field v-model:value="confirmed"
                  :error="confirmedError.length > 0"
                  :hint="confirmedError[0] || '' "
                  class="mt-12"
                  :disabled="loading"
                  size="large"
                  :trigger-password="true"
                  type="password"
                  label="Подтвердите новый пароль" />
    <ul>
      <li>
        <span class="body2">
          Для смены пароля введите старый пароль, дважды новый пароль и нажмите кнопку &laquo;Сменить пароль&raquo;
        </span>
      </li>
    </ul>

    <div class="user-change-password__actions mt-12">
      <b-button @click="submit"
                type="contained"
                :disabled="loading"
                size="small">
        Сменить пароль
      </b-button>
    </div>
  </div>

  <change-password-success-dialog ref="successDialog"
                                  @ok="successDialogOkEvent"
                                  @close="afterCloseSuccessDialog" />

  <change-password-error-dialog ref="errorDialog"
                                @ok="errorDialogOkEvent" />
</section>
</template>

<script>
import { useStore } from 'effector-vue/composition';
import { BTextField, BButton, BInlineAlert } from 'uikit';

import { useField, useForm } from 'vee-validate';
import { onMounted, ref } from 'vue';
import * as yup from 'yup';

import { VALID_REQUIRED } from '../app/ValidationMessage.js';
</script>

<script setup>

import ChangePasswordErrorDialog from './change-password/ChangePasswordErrorDialog.vue';
import ChangePasswordSuccessDialog from './change-password/ChangePasswordSuccessDialog.vue';
import { showErrorModalFx, showSuccessModalFx, userChangePasswordFx } from './UserChangePassword.js';

const successDialog = ref(null);
const errorDialog = ref(null);
const loading = useStore(userChangePasswordFx.pending);

const NEW_PASSWORD_MIN_LENGTH = 6;
// eslint-disable-next-line no-template-curly-in-string
const NEW_PASSWORD_MIN_MESSAGE = 'Пароль должен содержать не менее ${min} символов';

const NEW_PASSWORD_MAX_LENGTH = 32;
// eslint-disable-next-line no-template-curly-in-string
const NEW_PASSWORD_MAX_MESSAGE = 'Максимальная длинна пароля ${max} символов';

const NEW_PASSWORD_MATCH_LETTERS = /^(?=.*[a-z])/;
const NEW_PASSWORD_MATCH_LETTERS_MESSAGE = 'Пароль должен латинские содержать буквы';

const NEW_PASSWORD_CONFIRM_MESSAGE = 'Пароли не совпадают';

const validationSchema = yup.object({
  oldpassword: yup.string().required(VALID_REQUIRED),
  newpassword: yup.string()
    .required(VALID_REQUIRED)
    .min(NEW_PASSWORD_MIN_LENGTH, NEW_PASSWORD_MIN_MESSAGE)
    .max(NEW_PASSWORD_MAX_LENGTH, NEW_PASSWORD_MAX_MESSAGE)
    .matches(NEW_PASSWORD_MATCH_LETTERS, NEW_PASSWORD_MATCH_LETTERS_MESSAGE),
  confirmed: yup.string()
    .required(VALID_REQUIRED)
    .oneOf([yup.ref('newpassword'), null], NEW_PASSWORD_CONFIRM_MESSAGE)
});
const { validate, resetForm } = useForm({ validationSchema });

const { value: oldpassword, errors: oldpasswordError } = useField('oldpassword');
const { value: newpassword, errors: newpasswordError } = useField('newpassword');
const { value: confirmed, errors: confirmedError } = useField('confirmed');

const submit = async () => {
  try {
    const result = await validate();
    if (!result.valid) return;
    userChangePasswordFx({
      v_oldpassword: oldpassword.value,
      v_newpassword: newpassword.value
    });
  } catch (e) {
    console.error(e);
  }
};

const afterCloseSuccessDialog = () => {
  resetForm();
};
const successDialogOkEvent = () => {
  successDialog.value.close();
  afterCloseSuccessDialog();
};
const errorDialogOkEvent = () => {
  errorDialog.value.close();
};

onMounted(() => {
  showSuccessModalFx.use(() => {
    successDialog.value.open();
  });
  showErrorModalFx.use(() => {
    errorDialog.value.open();
  });
});

</script>

<style lang="scss">
.user-change-password {
  width: 552px;

  &__form {
    padding: 24px;
  }
}
</style>
