import { createEffect, forward } from 'effector';

import { createRequestFx } from '../app/http/Http.js';

export const userChangePasswordFx = createEffect(data => createRequestFx({
  data,
  config: {
    path: 'be_changepassword',
    method: 'put'
  }
}));
export const showSuccessModalFx = createEffect();
export const showErrorModalFx = createEffect();
forward({
  from: userChangePasswordFx.failData,
  to: showErrorModalFx
});
forward({
  from: userChangePasswordFx.doneData,
  to: showSuccessModalFx
});