<template>
<b-dialog-confirm ref="dialog"
                  @ok="disconnectStaticIp"
                  ok-type-btn="outlined"
                  cancel-text="Отменить"
                  ok-text="Отключить постоянный IР-адрес">
  <template #title>
    Статический IP-адрес
  </template>
  Вы&nbsp;собираетесь отключить услугу &laquo;Постоянный IP-адрес&raquo;
</b-dialog-confirm>
</template>

<script setup>
import { BDialogConfirm } from 'uikit';
import { ref } from 'vue';

import { beStatIpOff } from './BeStatIpOff.js';

const dialog = ref(null);

const open = () => dialog.value.open();
const close = () => dialog.value.close();

defineExpose({ open, close });

const disconnectStaticIp = () => {
  close();
  beStatIpOff();
};
</script>