<template>
<b-dialog-confirm ref="dialog"
                  @ok="okEvent"
                  ok-text="Понятно">
  <template #title>
    <div class="flex-center-y">
      <b-icon name="check_circled" theme="green" class="mr-8" />
      Статический IP-адрес
    </div>
  </template>
  <template #default>
    Заявка на&nbsp;подключение услуги &laquo;Постоянный IP-адрес&raquo; успешно заведена
  </template>
</b-dialog-confirm>
</template>

<script setup>
import { BDialogConfirm, BIcon } from 'uikit';

import { ref } from 'vue';

const dialog = ref(null);
const okEvent = () => close();
const open = () => dialog.value.open();
const close = () => dialog.value.close();
defineExpose({ open, close });
</script>