<template>
<section>
  <b-text type="h4">Новости</b-text>
  <div v-if="!loading" class="news-page__list">
    <template :key="index" v-for="(item, index) in items">
      <news-item :item="item" />
    </template>
  </div>
  <div v-else class="news-page__list">
    <template :key="index" v-for="(item, index) in skeletonArray">
      <news-skeleton-item />
    </template>
  </div>
</section>
</template>

<script>
export default {};
</script>

<script setup>
import { useStore } from 'effector-vue/composition';
import { BText } from 'uikit';

import { onBeforeMount } from 'vue';

import { $news, beGetnews } from './News.js';
import NewsItem from './NewsItem.vue';
import NewsSkeletonItem from './NewsSkeletonItem.vue';

const loading = useStore(beGetnews.pending);
const items = useStore($news);

const numberSkeletonItems = 4;
const skeletonArray = Array.from({ length: numberSkeletonItems });

onBeforeMount(() => {
  beGetnews();
});
</script>

<style lang="scss">
.news-page {
  &__list {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    align-items: start;
  }
}
</style>