<template>
<div class="h4 mt-26 mb-22">Статистика</div>
<internet-select-service />
<tab-nav class="mt-8" :links="links" />
<router-view class="mt-32" />
</template>

<script>
export default {};
</script>

<script setup>
import TabNav from '../../tab-nav/TabNav.vue';
import InternetSelectService from '../InternetSelectService.vue';
import { INTERNET_STATISTIC_BILL_ROUTE,
         INTERNET_STATISTIC_PAYMENTS_ROUTE, INTERNET_STATISTIC_SERVICE_ROUTE,
         INTERNET_STATISTIC_TRAFFIC_ROUTE } from '../RouterConst.js';

const links = [
  { text: 'Статистика по трафику', to: { name: INTERNET_STATISTIC_TRAFFIC_ROUTE } },
  { text: 'Статистика платежей', to: { name: INTERNET_STATISTIC_PAYMENTS_ROUTE } },
  { text: 'Статистика по выставленным счетам', to: { name: INTERNET_STATISTIC_BILL_ROUTE } },
  { text: 'Статистика по услугам', to: { name: INTERNET_STATISTIC_SERVICE_ROUTE } }
];
</script>
