import { attach, combine, createEffect, createEvent, createStore, sample } from 'effector';

import { createRequestFx } from '../app/http/Http.js';
import { serviceByRouteParamsFabric } from '../services/ServiceByRouteParams.js';

const { $service, setServiceParams } = serviceByRouteParamsFabric();

const $data = combine({ $service }, ({ $service }) => ({
  v_conumvolume: $service === null ? null : $service.conumvolume
}));

const beGetBillPlansOriginalFx = createEffect(data => createRequestFx({
  data,
  config: {
    path: 'be_getbillplans',
    method: 'get'
  }
}));

export const beGetBillPlansFx = attach({
  effect: beGetBillPlansOriginalFx,
  source: $data,
  mapParams: (params, data) => ({ ...params, ...data })
});

sample({
  clock: $data,
  filter: data => data.v_conumvolume,
  target: beGetBillPlansFx
});

const $plansList = createStore([]);

$plansList.on(beGetBillPlansFx.doneData, (_, response) => response.data.data);

export const $plansListWithActiveTariff = combine({
  $service, $plansList
}, ({ $service, $plansList }) => {
  const list = [];
  if ($service !== null) {
    list.push({
      nmbplan: $service.nmbplan,
      description: $service.bplaninfo
    });
  }
  list.push(...$plansList);
  return list;
});

export const $cobplan = createStore(null);

export const $selectedTariff = combine({
  $cobplan, $plansList
}, ({ $cobplan, $plansList }) => {
  if ($cobplan === null) return {};
  const findTariff = $plansList.find(tariff => tariff.cobplan === $cobplan);
  return findTariff ? findTariff : {};
});

const resetTariff = createEvent();
$plansList.reset(resetTariff);
$cobplan.reset(resetTariff);

export { setServiceParams, $service };
