import { attach, combine, createEvent, createStore, sample } from 'effector';

import { beNuminfo, getServicesFx, TV_TYPE } from '../services/Services.js';
import { servicesSum } from '../services/ServicesSum.js';

export const setTvService = createEvent();

export const $tvService = createStore(null);

export const resetTvService = createEvent();
$tvService.reset(resetTvService);
$tvService.on(setTvService, (_, service) => service);

export const getTvServicesWithParams = attach({
  effect: beNuminfo,
  mapParams: params => ({ ...params, v_coc: TV_TYPE })
});

export const $tvListWithParams = createStore([]);

$tvListWithParams.on(getTvServicesWithParams.doneData, (_, response) => {
  const services = response.data.data.services;
  return servicesSum(services);
});

export const $tvServiceWithParams = combine({
  service: $tvService,
  list: $tvListWithParams
}).map(({ service, list }) => {
  if (service === null) return null;
  const { nmbillgroup, conum } = service;
  return list.find(item => item.nmbillgroup === nmbillgroup && item.conum === conum);
});

sample({
  clock: getServicesFx,
  target: resetTvService
});
