import { format, parseISO } from 'date-fns';
import { attach, combine, createEffect, createEvent, createStore, forward, sample } from 'effector';

import { createRequestFx } from '../../app/http/Http.js';
import { isLoaded } from '../../app/IsLoaded.js';
import { paginationFabric } from '../../app/Pagination.js';
import { dateRangeFabric } from '../../statistic/Date.js';
import { $phoneService } from '../PhoneService.js';

const beDetCallsOriginalFx =  createEffect(data => createRequestFx({
  data,
  config: {
    path: 'be_det_calls',
    method: 'get'
  }
}));

const { $dateRange, setDateRange, $outputDateRange } = dateRangeFabric({
  start: null,
  end: null
});

const $data = combine({
  v_conum: $phoneService.map(service => service ? service.conum : null),
  $outputDateRange
}).map(({ v_conum, $outputDateRange }) => ({
  v_conum,
  ...$outputDateRange
}));

const beDetCallsWithDataFx = attach({
  effect: beDetCallsOriginalFx,
  source: { $data },
  mapParams: (params, { $data }) => ({ ...params, ...$data })
});

const $detCallsStat = createStore([]);

const { effect: beDetCallsFx, $pagination, api: paginationApi } = paginationFabric(beDetCallsWithDataFx);
$detCallsStat.on(beDetCallsFx.doneData, (_, response) => {
  const data =  response.data.data;
  const MIN_PER_HOUR = 60;
  return data.map(item => ({
    startdate: format(parseISO(item.startdate), 'dd.MM.yyyy HH:mm'),
    tonunber: item.tonunber,
    nmcity: item.nmcity,
    nmtraffictype: item.nmtraffictype,
    duration: `${item.duration / MIN_PER_HOUR} мин.`,
    amount: `${item.amount} руб`
  }));
});

const $isLoaded = isLoaded(beDetCallsFx);

const resetDetCallsStat = createEvent();

$detCallsStat.reset(resetDetCallsStat);
$isLoaded.reset(resetDetCallsStat);
$dateRange.reset(resetDetCallsStat);

forward({
  from: $phoneService,
  to: resetDetCallsStat
});

export const getBeDetCalls = createEvent();

sample({
  clock: getBeDetCalls,
  target: [paginationApi.resetPagination, beDetCallsFx]
});

export {
  $pagination, paginationApi, beDetCallsFx, $dateRange, setDateRange, $detCallsStat, $isLoaded, resetDetCallsStat
};
