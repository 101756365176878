import { format } from 'date-fns';
import { createEvent, restore } from 'effector';

const outputDateFormat = 'yyyy-MM-dd';

export const formatOutputDate = date => date ? format(date, outputDateFormat) : null;

export const dateRangeFabric = (rangeValue = {}) => {
  const setDateRange = createEvent();
  const $dateRange = restore(setDateRange, rangeValue);
  const $outputDateRange = $dateRange.map(range => ({
    v_startdate: formatOutputDate(range.start),
    v_uptodate: formatOutputDate(range.end)
  }));
  return {
    setDateRange, $dateRange, $outputDateRange
  };
};