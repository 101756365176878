import { createEffect, createEvent, createStore, forward, sample } from 'effector';

import { createRequestFx } from '../../app/http/Http.js';

import { $internetService } from '../InternetService.js';

export const getStaticIpFx =  createEffect(data => createRequestFx({
  data,
  config: {
    path: 'be_getnumstipinfo',
    method: 'get'
  }
}));

export const $internetStaticIp = createStore(null);

$internetStaticIp.on(getStaticIpFx.doneData, (_, { data: responseData }) => responseData.data);

sample({
  clock: $internetService,
  filter: service => service !== null,
  target: getStaticIpFx,
  fn: service => ({
    v_conumvolume: service.conumvolume
  })
});

export const $staticIpInProgress = createStore(false);
const APPLICATION_IN_PROGRESS = -6166;

$staticIpInProgress.on(getStaticIpFx.failData, (_, { response }) => {
  const errorData = response.data.error;
  return errorData.v_result === APPLICATION_IN_PROGRESS;
});

const resetStaticIpInProgress = createEvent();
$staticIpInProgress.reset(resetStaticIpInProgress);

forward({
  from: $internetService,
  to: resetStaticIpInProgress
});