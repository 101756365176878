<template>
<section>
  <statistic-control v-model:value="date" @submit="submit" :loading="loading" />
  <empty-result v-if="isLoaded && items.length === 0 && !loading" />
  <b-table v-if="items.length > 0 && isLoaded && !loading"
           class="phone-statistic-det-calls_scrollable mt-16"
           :fields="fields"
           :items="items" />
  <b-pagination v-if="items.length > 0 && isLoaded && !loading"
                :pagination="pagination"
                :pagination-api="paginationApi" />
</section>
</template>

<script setup>

import { useStore } from 'effector-vue/composition';
import { computed, onBeforeUnmount } from 'vue';

import { onBeforeRouteUpdate } from 'vue-router';

import BPagination from '../../services/BPagination.vue';
import BTable from '../../services/BTable.vue';
import EmptyResult from '../../statistic/EmptyResult.vue';

import StatisticControl from '../../statistic/StatisticControll.vue';

import { $dateRange, $detCallsStat,
         $isLoaded,
         $pagination,
         beDetCallsFx, getBeDetCalls, paginationApi,
         resetDetCallsStat, setDateRange } from './BeDetCalls.js';

const dateRangeStore = useStore($dateRange);
const date = computed({
  get() {
    return dateRangeStore.value;
  },
  set(value) {
    setDateRange(value);
  }
});

const items = useStore($detCallsStat);
const fields = [
  { title: 'Дата, время (начала вызова)', key: 'startdate' },
  { title: 'Куда звонок', key: 'tonunber' },
  { title: 'Направление', key: 'nmcity' },
  { title: 'Тип трафика', key: 'nmtraffictype' },
  { title: 'Длительность ', key: 'duration' },
  { title: 'Стоимость', key: 'amount' }
];
const submit = () => {
  getBeDetCalls();
};
const isLoaded = useStore($isLoaded);
const loading = useStore(beDetCallsFx.pending);
onBeforeRouteUpdate(() => {
  resetDetCallsStat();
});
onBeforeUnmount(() => {
  resetDetCallsStat();
});

const pagination = useStore($pagination);
</script>

<style lang="scss">
.phone-statistic-det-calls {
  &_scrollable {
    max-height: 37vh;
    overflow-y: auto;
  }
}
</style>
