<template>
<section class="bordered user-service">
  <div class="user-service__header">
    {{ service.servicetype_name }}
    <service-status :status="service.status"
                    class="user-service__status" />
  </div>
  <b-list-view class="user-service__account" body="Лицевой счёт" :title="service.nmbillgroup || 'нет данных'" :reversed="true" />
  <b-list-view class="user-service__balance" body="Текущий баланс" :title="service.saldo || 'нет данных'" :reversed="true" />
  <div class="user-service__edit">
    <b-button v-if="isActive"
              @click="goToBlock"
              type="outlined"
              left-icon="ablock"
              size="small">
      Заблокировать
    </b-button>
    <b-button v-else-if="isBlock"
              @click="goToBlock"
              type="plain"
              left-icon="check_circled"
              size="small">
      Разблокировать
    </b-button>
    <b-button v-else
              type="plain"
              left-icon="check_circled"
              size="small"
              disabled>
      Подключить
    </b-button>
  </div>
</section>
</template>

<script setup>
import { BListView, BButton } from 'uikit';
import { computed, toRefs } from 'vue';

import { useRouter } from 'vue-router';

import { INTERNET_BLOCK_ROUTE } from '../../internet/RouterConst.js';
import { PHONE_BLOCK_PAGE } from '../../phone/RouterConst.js';
import { INTERNET_TYPE, PHONE_TYPE, TV_TYPE } from '../../services/Services.js';
import ServiceStatus from '../../services/ServiceStatus.vue';
import { TV_SERVICE_SETTING } from '../../tv/RouterConst.js';
const props = defineProps({
  service: {
    type: Object,
    required: true
  }
});
const { service } = toRefs(props);
const isActive = computed(() => {
  const status = service.value.status;
  return status && status == '1';
});
const isBlock = computed(() => {
  const status = service.value.status;
  return status && status == '2';
});
const router = useRouter();
const goToBlock = () => {
  const routesList = {
    [INTERNET_TYPE]: INTERNET_BLOCK_ROUTE,
    [PHONE_TYPE]: PHONE_BLOCK_PAGE,
    [TV_TYPE]: TV_SERVICE_SETTING
  };
  const nextRouter = routesList[props.service.coc];

  if (nextRouter) {
    router.push({
      name: nextRouter,
      params: {
        nmbillgroup: props.service.nmbillgroup,
        conum: props.service.conum
      }
    });
  }
};
</script>

<style lang="scss">
.user-service {
  padding-left: 24px;
  padding-top: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
  display: grid;
  grid-template: 'header header header'
    'plan plan plan'
    'account balance edit'
    / 1fr 1fr 1fr;
  align-items: center;

  &__header {
    display: flex;
    align-items: center;
    @include fontStyle(h5);
    font-weight: bold;
    color: var(--color-text-active);
    grid-area: header;
  }

  &__status{
    margin-left: 16px;
  }

  &__account{
    grid-area: account;
  }

  &__balance{
    grid-area: balance;
  }

  &__edit{
    grid-area: edit;
  }

  &__plan{
    grid-area: plan;
  }
}
</style>