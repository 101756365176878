import axios from 'axios';
import { attach, createEffect } from 'effector';

import { $token } from './Token.js';

export const getHost = () => {
  const envHost = process.env.VUE_APP_API_HOST;
  if (!envHost) {
    console.error('VUE_APP_API_HOST is undefined');
    return '';
  }
  const trimmed = envHost.trim();
  return trimmed.endsWith('/') ? trimmed : `${trimmed}/`;
};

const instance = axios.create({ baseURL: getHost() });
const UNAUTHORIZED_CODE = 401;
instance.interceptors.response.use(response => response,
  error => {
    if (error.response?.status === UNAUTHORIZED_CODE && error.response?.data?.message === 'Authorization token expired') {
      window.location.assign('/login');
    }
    return Promise.reject(error);
  });

const backendRequestFx = createEffect(({ token, data, config }) => {
  const mergedConfig = {
    method: config.method,
    headers: {
      Authorization: `Bearer ${token}`
    },
    url: `api/v1/${config.path}`
  };
  if (config.responseType) {
    mergedConfig.responseType = config.responseType;
  }
  if (config.method === 'get') {
    mergedConfig.params = data;
  } else {
    mergedConfig.data = data;
  }
  return instance(mergedConfig);
});

export const createRequestFx = attach({
  effect: backendRequestFx,
  source: $token,
  mapParams: ({ config, data }, token) => ({ data, config, token })
});
