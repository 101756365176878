<template>
<b-dialog-confirm ref="dialog"
                  @ok="okEvent"
                  ok-text="Понятно">
  <template #title>
    <div class="flex-center-y">
      <b-icon name="cancel" theme="red" class="mr-8" />
      Что-то пошло не так
    </div>
  </template>
  <template #default>
    Не удалось активировать временную отключение блокировки. Попробуйте повторить операцию чуть позже
  </template>
</b-dialog-confirm>
</template>

<script setup>
import { BDialogConfirm, BIcon } from 'uikit';
import { ref } from 'vue';

const dialog = ref(null);
const okEvent = () => close();

const open = () => dialog.value.open();
const close = () => dialog.value.close();
defineExpose({ open, close });
</script>
