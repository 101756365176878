
import StatisticBillPage from '../statistic/StatisticBillPage.vue';
import StatisticPaymentsPage from '../statistic/StatisticPaymentsPage.vue';
import StatisticServicePage from '../statistic/StatisticServicePage.vue';

import TariffPage from '../tariff/TariffPage.vue';

import InternetBlockPage from './block/InternetBlockPage.vue';
import InternetInfoPage from './InternetInfoPage.vue';
import InternetPage from './InternetPage.vue';
import InternetServicePage from './InternetServicePage.vue';
import InternetServiceSetting from './InternetServiceSetting.vue';
import * as internetRouter from './RouterConst.js';
import StaticIpPage from './static-ip/StaticIpPage.vue';
import InternetStatisticPage from './statistic/InternetStatisticPage.vue';
import InternetStatisticTrafficPage from './statistic/InternetStatisticTrafficPage.vue';

export default [
  {
    name: internetRouter.INTERNET_ROUTE,
    path: '/internet',
    component: InternetPage,
    children: [
      {
        name: internetRouter.INTERNET_SERVICE_ROUTE,
        path: '/internet/:nmbillgroup/:conum',
        component: InternetServicePage,
        children: [
          {
            name: internetRouter.INTERNET_INFO_ROUTE,
            path: 'info',
            component: InternetInfoPage
          },
          {
            name: internetRouter.INTERNET_STATISTIC_ROUTE,
            path: 'statistic',
            component: InternetStatisticPage,
            redirect: () => ({ name: internetRouter.INTERNET_STATISTIC_TRAFFIC_ROUTE }),
            children: [
              {
                name: internetRouter.INTERNET_STATISTIC_TRAFFIC_ROUTE,
                path: 'traffic',
                component: InternetStatisticTrafficPage
              },
              {
                name: internetRouter.INTERNET_STATISTIC_PAYMENTS_ROUTE,
                path: 'payments',
                component: StatisticPaymentsPage
              },
              {
                name: internetRouter.INTERNET_STATISTIC_BILL_ROUTE,
                path: 'bill',
                component: StatisticBillPage
              },
              {
                name: internetRouter.INTERNET_STATISTIC_SERVICE_ROUTE,
                path: 'service',
                component: StatisticServicePage
              }
            ]
          },
          {
            name: internetRouter.INTERNET_SERVICE_SETTING_ROUTE,
            path: 'setting',
            component: InternetServiceSetting,
            redirect: () => ({ name: internetRouter.INTERNET_STATIC_IP_ROUTE }),
            children: [
              {
                name: internetRouter.INTERNET_STATIC_IP_ROUTE,
                path: 'static',
                component: StaticIpPage
              },
              {
                name: internetRouter.INTERNET_BLOCK_ROUTE,
                path: 'block',
                component: InternetBlockPage
              },
              {
                name: internetRouter.INTERNET_TARIFF_ROUTE,
                path: 'tariff',
                component: TariffPage
              }
            ]
          }
        ]
      }

    ]
  }
];