<template>
<section v-if="!loading && token" class="beeatlant-app">
  <p-header class="beeatlant-app__header" />
  <side-navigation class="beeatlant-app__nav" />
  <section class="beeatlant-app__page">
    <router-view />
  </section>
  <b-footer class="beeatlant-app__footer" />
</section>
</template>

<script setup>
import { useStore } from 'effector-vue/composition';

import { onBeforeMount } from 'vue';

import { getServicesFx } from '../services/Services.js';
import SideNavigation from '../side-navigation/SideNavigation.vue';

import { userInfoFx } from '../user/User.js';

import BFooter from './footer/BFooter.vue';
import PHeader from './header/BHeader.vue';

import { $token, restoreToken } from './http/Token.js';

const loading = useStore(restoreToken.pending);
const token = useStore($token);
onBeforeMount(async () => {
  await restoreToken();
  userInfoFx();
  getServicesFx();
});
</script>

<style lang="scss">
.beeatlant-app {
  height: 100vh;
  display: grid;
  grid-template: 'header header header' 64px
    'navigation page ...'
    'navigation footer ...' 124px
    / 256px 1184px;

  &__header{
    grid-area: header;
  }

  &__page{
    grid-area: page;
    padding-left: 24px;
    padding-top: 20px;
    padding-right: 40px;
  }

  &__nav{
    grid-area: navigation;
  }

  &__footer{
    grid-area: footer;
  }
}
</style>