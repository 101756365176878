<template>
<router-view />
</template>

<script setup>
import { useStore } from 'effector-vue/composition';
import { onMounted, watch } from 'vue';
import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router';

import { $phoneList } from '../services/Services.js';

import { $phoneService, getPhoneServicesWithParamsFx, setPhoneService } from './PhoneService.js';
import { PHONE_INFO_ROUTE, PHONE_ROUTE, PHONE_SERVICE_ROUTE } from './RouterConst.js';

const list = useStore($phoneList);
const phoneService = useStore($phoneService);
const router = useRouter();
const route = useRoute();
const findService = (nmbillgroup, conum) => list.value.find(service => service.conum == conum && service.nmbillgroup == nmbillgroup);
const redirect = (routeName, service) => {
  router.push({
    name: routeName,
    params: {
      nmbillgroup: service.nmbillgroup,
      conum: service.conum
    }
  });
};
watch(list, () => {
  if (list.value.length === 0) return;
  if (phoneService.value != null) return;

  const { conum, nmbillgroup } = route.params;
  
  if (conum && nmbillgroup) {
    const finedService = findService(nmbillgroup, conum);
    if (finedService) {
      setPhoneService(finedService);
      redirect(route.name, finedService);
      return;
    }
  }
  const service = list.value.at(0);
  setPhoneService(service);
  redirect(PHONE_SERVICE_ROUTE, service);
}, { immediate: true });

onMounted(() => {
  getPhoneServicesWithParamsFx();
  
  if (phoneService.value && route.name === PHONE_ROUTE) {
    redirect(PHONE_INFO_ROUTE, phoneService.value);
  }
});

onBeforeRouteUpdate(to => {
  const { conum, nmbillgroup } = to.params;
  if (conum && nmbillgroup) {
    const finedService = findService(nmbillgroup, conum);
    if (finedService) {
      setPhoneService(finedService);
    }
  }
});
</script>
