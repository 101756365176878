export const INTERNET_INFO_ROUTE = 'internet-info';
export const INTERNET_ROUTE = 'internet';
export const INTERNET_SERVICE_ROUTE = 'internet-service';
export const INTERNET_SERVICE_SETTING_ROUTE = 'internet-service-setting';
export const INTERNET_STATISTIC_BILL_ROUTE = 'internet-statistic-bill';
export const INTERNET_STATISTIC_PAYMENTS_ROUTE = 'internet-statistic-payments';
export const INTERNET_STATISTIC_ROUTE = 'internet-statistic';
export const INTERNET_STATISTIC_SERVICE_ROUTE = 'internet-statistic-service';
export const INTERNET_STATISTIC_TRAFFIC_ROUTE = 'internet-statistic-traffic';
export const INTERNET_STATIC_IP_ROUTE = 'internet-static-ip';
export const INTERNET_BLOCK_ROUTE = 'internet-block';
export const INTERNET_TARIFF_ROUTE = 'internet-tariff';