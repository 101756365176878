<template>
<section class="b-table__container">
  <table class="b-table " :class="{'b-table--empty': empty}">
    <thead v-if="!empty || isEmptyResult">
      <tr>
        <th v-if="showSelect" class="b-table__th"></th>
        <th :key="field.key + index"
            v-for="(field, index) in fields"
            class="b-table__th"
            :style="setupCellStyle(field)">
          <slot v-if="field.key" :name="'th_' + field.key">
            {{ field.title }}
          </slot>
        </th>
      </tr>
    </thead>
    <tbody>
      <template v-if="items.length > 0">
        <template :key="row_index" v-for="(item, row_index) in items">
          <tr v-if="fullRowCallback(item)">
            <td :colspan="fields.length" class="b-table__td">
              <slot name="full-row" :item="item"></slot>
            </td>
          </tr>
          <tr v-else @click="clickRow(item)" :class="rowClass(item)">
            <td v-if="showSelect" class="b-table__td">
              <b-radio v-if="item[itemKey]"
                       @update:model-value="updateModel"
                       :model-value="modelValue"
                       :value="item[itemKey]" />
            </td>
            <td :key="field.key + cell_index"
                v-for="(field, cell_index) in fields"
                :style="setupCellStyle(field)"
                class="b-table__td">
              <slot :name="field.key"
                    :item="item"
                    :items="items"
                    :field="field"
                    :fields="fields">
                <template v-if="isExistKey(item, field)">
                  {{ getValue(item, field) }}
                </template>
              </slot>
            </td>
          </tr>
        </template>
      </template>
      <slot name="total"></slot>
    </tbody>
  </table>
  <empty-result v-if="isEmptyResult" />
</section>
</template>

<script>
const typeSelect = {
  radio: 'radio',
  checkbox: 'checkbox'
};
</script>

<script setup>

import { BRadio } from 'uikit';
import { computed } from 'vue';

import EmptyResult from '../statistic/EmptyResult.vue';

const props = defineProps({
  items: { type: Array, default: () => [] },
  fields: { type: Array, default: () => [] },
  fullRowCallback: { type: Function, default: () => false },
  isEmptyResult: { type: Boolean, default: false },
  itemKey: { type: String, default: '' },
  // eslint-disable-next-line vue/require-default-prop
  modelValue: {
    validator: () => true
  },
  //TODO добавить checkbox
  // eslint-disable-next-line vue/no-unused-properties
  typeSelect: {
    type: String,
    default: typeSelect.radio
  },
  showSelect: { type: Boolean, default: false }
});
const rowClass = item => {
  const elClass = {};
  if (props.showSelect) {
    elClass['b-table__row--selected'] = item[props.itemKey] === props.modelValue;
    elClass['b-table__row--hoverable'] = true;
  }
  return elClass;
};
const emit = defineEmits({
  'update:modelValue': () => true
});
const getValue = (item, field) => {
  const value = item[field.key];
  if (field.formatter) return field.formatter(value);
  return value;
};
const setupCellStyle = field => field.style ? field.style : {};
const empty = computed(() => props.items.length  === 0);
const  isExistKey = (item, field) =>
// eslint-disable-next-line no-prototype-builtins,implicit-arrow-linebreak
  item.hasOwnProperty(field.key);

const updateModel = value => {
  emit('update:modelValue', value);
};

const clickRow = item => {
  if (props.showSelect) {
    updateModel(item[props.itemKey]);
  }
};
</script>

<style lang="scss">
.b-table{
  width: 100%;
  border-collapse: collapse;

  &__container {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .1);
  }

  tbody{
    tr{
      height: 52px;
    }
  }

  &__th{
    height: 56px;
    text-align: left;
    padding-left: 16px;
    padding-right: 16px;
    color: rgba(0, 0, 0, .87);
    @include fontStyle(subtitle3);
  }

  &__td{
    padding-left: 16px;
    padding-right: 16px;
    @include fontStyle(body3);
  }

  tr:not(:last-child) &__td,
  &__th{
    border-bottom: 1px solid rgba(0, 0, 0, .1);
  }

  &--empty &__th{
    border-bottom: 0;
  }

  &__row {
    &--hoverable{
      &:hover{
        cursor: pointer;
        background: rgba(0, 0, 0, .08);
      }
    }
  }

  &__row--selected{
    background: rgba(0, 0, 0, .04);
  }
}
</style>