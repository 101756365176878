<template>
<b-dialog ref="modal" teleport="#dialogs">
  <div class="widget-modal-item">
    <b-icon name="check_circled" class="text-green widget-modal-item__icon" />
    <div class="widget-modal-item__content">
      <slot></slot>
    </div>
    <b-button @click="close">{{ okText }}</b-button>
  </div>
</b-dialog>
</template>

<script setup>
import { BDialog, BIcon, BButton } from 'uikit';
import { ref } from 'vue';

const modal = ref(null);

const close = () => modal.value.close();
const open = () => modal.value.open();

defineProps({
  okText: { type: String, default: 'Ok' }
});
defineExpose({ open, close });
</script>

<style scoped lang="scss" src="./WidgetModal.scss" />
