<template>
<section>
  <b-text class="mt-16" type="h4">Информация о сервисе</b-text>
  <internet-select-service class="mt-16" />
  <service-info @block="goToBlock"
                :loading="loadingInternetParams"
                class="mt-16"
                :service="internetServiceWithParams || {}" />
  <b-text class="mt-32" type="h4">Подключенные услуги</b-text>
  <service-tariff v-if="internetService"
                  class="mt-16"
                  :link="INTERNET_TARIFF_ROUTE"
                  :service="internetService" />
  <static-ip-info class="mt-16" />
</section>
</template>

<script setup>

import { useStore } from 'effector-vue/composition.mjs';
import { BText } from 'uikit';

import { useRouter } from 'vue-router';

import ServiceInfo from '../services/ServiceInfo.vue';

import ServiceTariff from '../services/ServiceTariff.vue';

import InternetSelectService from './InternetSelectService.vue';
import { $internetService, $internetServiceWithParams, getInternetServicesWithParams } from './InternetService.js';
import { INTERNET_BLOCK_ROUTE, INTERNET_TARIFF_ROUTE } from './RouterConst.js';
import StaticIpInfo from './static-ip/StaticIpInfo.vue';

const internetService = useStore($internetService);
const internetServiceWithParams = useStore($internetServiceWithParams);
const loadingInternetParams = useStore(getInternetServicesWithParams.pending);
const router = useRouter();
const goToBlock = () => {
  router.push({ name: INTERNET_BLOCK_ROUTE });
};
</script>