<template>
<div class="flex-center-y">
  <b-datepicker-range @update:value="update" :value="value" label="Дата" class="mr-16" />
  <b-button @click="submit" :disabled="disabledSubmit" type="outlined">Показать</b-button>
</div>
</template>

<script setup>
import { BDatepickerRange, BButton } from 'uikit';
import { computed } from 'vue';

const props = defineProps({
  value: { type: [Object, String], required: true },
  loading: { type: Boolean, default: false }
});
const emit = defineEmits({
  'update:value': () => true,
  'submit': () => true
});
const disabledSubmit = computed(() => {
  const isObjectValue = typeof props.value === 'object' && (!props.value.start || !props.value.end);
  const isStringValue = typeof props.value === 'string' && props.value.length === 0;
  
  return props.loading || (isObjectValue || isStringValue);
});
const submit = () => {
  emit('submit');
};
const update = value => {
  emit('update:value', value);
};

</script>