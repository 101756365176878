<template>
<router-view />
</template>

<script setup>
import { useStore } from 'effector-vue/composition';
import { onMounted, watch } from 'vue';
import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router';

import { $tvList } from '../services/Services.js';

import { TV_INFO_ROUTE, TV_ROUTE, TV_SERVICE_ROUTE } from './RouterConst.js';
import { $tvService, getTvServicesWithParams, setTvService } from './TvService.js';

const list = useStore($tvList);
const tvService = useStore($tvService);
const router = useRouter();
const route = useRoute();
const findService = (nmbillgroup, conum) => list.value.find(service => service.conum == conum && service.nmbillgroup == nmbillgroup);
const redirect = (routeName, service) => {
  router.push({
    name: routeName,
    params: {
      nmbillgroup: service.nmbillgroup,
      conum: service.conum
    }
  });
};
watch(list, () => {
  if (list.value.length === 0) return;
  if (tvService.value != null) return;

  const { conum, nmbillgroup } = route.params;

  if (conum && nmbillgroup) {
    const finedService = findService(nmbillgroup, conum);
    if (finedService) {
      setTvService(finedService);
      redirect(route.name, finedService);
      return;
    }
  }
  const service = list.value.at(0);
  setTvService(service);
  redirect(TV_SERVICE_ROUTE, service);
}, { immediate: true });

onMounted(() => {
  getTvServicesWithParams();

  if (tvService.value && route.name === TV_ROUTE) {
    redirect(TV_INFO_ROUTE, tvService.value);
  }
});

onBeforeRouteUpdate(to => {
  const { conum, nmbillgroup } = to.params;
  if (conum && nmbillgroup) {
    const finedService = findService(nmbillgroup, conum);
    if (finedService) {
      setTvService(finedService);
    }
  }
});
</script>
