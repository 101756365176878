<template>
<section>
  <div v-if="loading" class="box-loader">
    <b-circular-progress :loading="loading" />
  </div>
  <b-table v-if="!loading" :fields="fields" class="phone-services-table_scrollable" :items="items" />
</section>
</template>

<script setup>
import { useStore } from 'effector-vue/composition';

import { BCircularProgress } from 'uikit';

import BTable from '../services/BTable.vue';

import { getServicesFx } from '../services/Services.js';

import { $phoneServiceTable } from './PhoneServiceTable.js';

const fields = [
  { title: 'Услуга', key: 'service' },
  { title: 'Статус', key: 'status' },
  { title: 'Абонентская плата', key: 'amount' }
];
const items = useStore($phoneServiceTable);
const loading = useStore(getServicesFx.pending);
</script>

<style lang="scss">
.phone-services-table {
  &_scrollable {
    max-height: 37vh;
    overflow-y: auto;
  }
}
</style>
