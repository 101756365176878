<template>
<select-service v-if="internetService"
                :selected-service="internetService"
                :list="internetList" />
</template>

<script setup>
import { useStore } from 'effector-vue/composition.mjs';

import SelectService from '../services/SelectService.vue';
import { $internetList } from '../services/Services.js';

import { $internetService } from './InternetService.js';
const internetService = useStore($internetService);
const internetList = useStore($internetList);
</script>
