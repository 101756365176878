<template>
<div class="user-edit-info">
  <b-dialog ref="dialog" class="user-edit-info__dialog">
    <div class="user-edit-info__header">Редактировать личные данные</div>
    <div class="user-edit-info__subtitle">Форма для редактирования ваших персональных данных</div>

    <template v-if="loading">
      <div class="flex-center-xy">
        <b-circular-progress class="mt-32 mb-32" :loading="loading" />
      </div>
    </template>
    <template v-else>
      <b-text-field v-model:value="phone"
                    size="large"
                    label="Контактный телефон"
                    class="mt-16"
                    prefix="+7"
                    :hint="phoneError"
                    :error="phoneError && phoneError.length > 0" />
      <b-text-field v-model:value="phones"
                    size="large"
                    label="Дополнительные контакты"
                    class="mt-16"
                    prefix="+7"
                    :hint="phonesError"
                    :error="phonesError && phonesError.length > 0" />
      <b-text-field v-model:value="sms"
                    size="large"
                    label="Телефон для sms"
                    class="mt-16"
                    prefix="+7"
                    :hint="smsError"
                    :error="smsError && smsError.length > 0" />
      <b-text-field v-model:value="email"
                    size="large"
                    label="E-mail"
                    class="mt-16"
                    :hint="emailError"
                    :error="emailError && emailError.length > 0" />
      <b-text-field v-model:value="emailsms"
                    size="large"
                    label="Дополнительный E-mail"
                    class="mt-16"
                    :hint="emailsmsError"
                    :error="emailsmsError && emailsmsError.length > 0" />
      <div class="user-edit-info__actions">
        <b-button @click="close"
                  class="mr-16"
                  type="outlined"
                  size="medium">
          Отменить
        </b-button>
        <b-button @click="edit"
                  :disabled="Object.values(errors).length > 0"
                  type="contained"
                  size="medium">
          Редактировать
        </b-button>
      </div>
    </template>
  </b-dialog>
</div>
</template>

<script setup>
import { forward } from 'effector';
import { useStore } from 'effector-vue/composition';
import { BButton, BTextField, BCircularProgress, BDialog } from 'uikit';
import { useField, useForm } from 'vee-validate';
import { onMounted, ref } from 'vue';

import { yup } from '../app/Yup.js';

import { editUserInfoFx, closeFx, openFx } from './UserEditInfo.js';

const props = defineProps({
  info: {
    type: Object,
    required: true
  }
});

const telPrefix =  '+7';
const YUP_PHONE_MIN_LENGTH = 10;
const YUP_EMAIL_VALIDATION_MESSAGE = 'Введите правильный email';
const YUP_PHONE_VALIDATION_MESSAGE = 'Введите правильный телефон';
const validationSchema =  yup.object({
  phone: yup.number().required().len(YUP_PHONE_MIN_LENGTH, YUP_PHONE_VALIDATION_MESSAGE),
  phones: yup.number().required().len(YUP_PHONE_MIN_LENGTH, YUP_PHONE_VALIDATION_MESSAGE),
  sms: yup.number().required().len(YUP_PHONE_MIN_LENGTH, YUP_PHONE_VALIDATION_MESSAGE),
  email: yup.string().required().email(YUP_EMAIL_VALIDATION_MESSAGE),
  emailsms: yup.string().required().email(YUP_EMAIL_VALIDATION_MESSAGE)
});
const { validate, errors } = useForm({ validationSchema });

const { value: phone, errorMessage: phoneError } = useField('phone');
const { value: phones, errorMessage: phonesError } = useField('phones');
const { value: sms, errorMessage: smsError } = useField('sms');
const { value: email, errorMessage: emailError } = useField('email');
const { value: emailsms, errorMessage: emailsmsError } = useField('emailsms');
const dialog = ref(null);
const loading = useStore(editUserInfoFx.pending);
const close = () => {
  dialog.value.close();
};
const open = () => {
  dialog.value.open();
};

forward({
  from: editUserInfoFx.doneData,
  to: closeFx
});

const edit = async () => {
  const result = await validate();
  if (!result.valid) return;
  editUserInfoFx({
    v_phone: telPrefix + phone.value,
    v_phones: telPrefix + phones.value,
    v_sms: telPrefix + sms.value,
    v_email: email.value,
    v_emailsms: emailsms.value
  });
};

const transformPhone = number => {
  const deleteStringNumber = 2;
  if (typeof number === 'string' && number.startsWith('+7')) {
    return number.slice(deleteStringNumber);
  }
  return number;
};
onMounted(() => {
  phone.value = transformPhone(props.info.v_phone);
  phones.value = transformPhone(props.info.v_phones);
  sms.value = transformPhone(props.info.v_sms);
  email.value = props.info.v_email;
  emailsms.value = props.info.v_emailsms;
});

openFx.use(() => open());
closeFx.use(() => close());

</script>

<style lang="scss">
.user-edit-info {
  &__dialog {
    width: 560px;
  }

  &__header {
    @include fontStyle(h5);
  }

  &__subtitle {
    @include fontStyle(body2);
    margin-top: 16px;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 16px;
  }
}
</style>
