<template>
<svg width="103" height="20" viewBox="0 0 103 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M84.0408 4.52032C86.0642 4.52032 87.5037 3.45719 87.7792 1.12529H85.7907C85.5851 1.94786 85.1039 2.35914 84.0427 2.35914C82.9796 2.35914 82.4306 1.94786 82.2599 1.12529H80.2714C80.543 3.45719 82.0174 4.52032 84.0408 4.52032Z" fill="black" />
  <path d="M43.2132 14.0051V5.58351H40.7106V17.8599H43.318L48.3581 9.2967V17.8599H50.8607V5.58351H48.2553L43.2132 14.0051Z" fill="black" />
  <path d="M32.8322 5.68628C30.8495 5.68628 29.1578 6.60973 28.2828 8.3247C28.407 5.54272 28.9599 3.88788 32.1803 3.4572L37.1875 2.77044L36.9819 0.0272522L31.769 0.746999C27.276 1.36392 26.0072 4.82879 26.0072 10.2802C26.0072 15.0469 28.1684 18.0985 32.1803 18.0985C36.0895 18.0985 38.2157 15.0798 38.2157 11.549C38.2157 8.01818 35.8508 5.68628 32.8322 5.68628ZM32.1803 15.3902C29.9163 15.3902 28.5797 13.7451 28.5797 11.2755V10.0067C29.0588 9.04639 30.3296 8.29172 32.1803 8.29172C34.1359 8.29172 35.6433 9.21711 35.6433 11.549C35.6433 13.8809 34.4443 15.3902 32.1803 15.3902Z" fill="black" />
  <path d="M99.6675 5.58351V10.1775H94.798V5.58351H92.2954V17.8599H94.798V12.715H99.6675V17.8599H102.172V5.58351H99.6675Z" fill="black" />
  <path d="M81.5032 14.0051V5.58351H78.9987V17.8599H81.606L86.6462 9.2967V17.8599H89.1488V5.58351H86.5434L81.5032 14.0051Z" fill="black" />
  <path d="M55.3634 13.0933C55.0899 14.4649 54.7116 15.0488 53.9569 15.0488C53.5456 15.0488 53.2371 15.0139 53.0315 14.946V17.792C53.34 17.9298 53.6154 18.0326 54.2653 18.0326C55.569 18.0326 56.4944 17.2779 56.9406 16.112C57.2142 15.3922 57.3868 14.397 57.5246 13.1282C57.6623 11.8595 57.7651 10.1794 57.7651 8.19089H61.0923V17.8619H63.5949V5.58351H55.6738C55.6738 9.287 55.571 11.7217 55.3634 13.0933Z" fill="black" />
  <path d="M75.4078 14.5328L75.4427 9.66335C75.4776 6.57678 73.59 5.30801 70.8468 5.30801C68.5828 5.30801 66.5264 6.54186 66.2179 9.42279H68.6177C68.7554 8.29176 69.6459 7.91346 70.8468 7.91346C72.0768 7.91346 72.9013 8.29176 73.0254 9.55471L69.9563 10.7944C67.865 11.6519 66.2877 12.6122 66.2877 14.6356C66.2877 16.7269 67.4537 18.0656 69.7856 18.0656C71.3958 18.0656 72.4143 17.309 73.2291 15.8307C73.3513 17.309 74.331 17.9627 76.0266 17.9627H76.7134V15.5959H76.5077C75.7162 15.5959 75.4078 15.2875 75.4078 14.5328ZM70.709 15.4931C69.5431 15.4931 68.6856 15.1846 68.6856 14.1215C68.6856 13.1961 69.4053 12.7848 70.6411 12.2688L73.0312 11.2755L73.006 14.6007C72.5947 15.1167 71.7722 15.4931 70.709 15.4931Z" fill="black" />
  <path d="M10.0047 19.9977C15.529 19.9977 20.0074 15.5211 20.0074 9.9989C20.0074 4.4767 15.529 6.10352e-05 10.0047 6.10352e-05C4.48032 6.10352e-05 0.00195312 4.4767 0.00195312 9.9989C0.00195312 15.5211 4.48032 19.9977 10.0047 19.9977Z" fill="#FFC800" />
  <path d="M14.3089 17.4485C10.912 19.4079 7.22787 19.759 4.87463 18.5853C7.88748 20.3798 11.7559 20.535 15.0073 18.659C18.2588 16.7811 20.0591 13.355 20.0106 9.84941C19.8516 12.4743 17.7059 15.489 14.3089 17.4485Z" fill="black" />
  <path d="M11.2725 0.733317C11.9613 1.92449 10.2967 4.17491 7.55354 5.75602C4.81035 7.33713 2.03031 7.6553 1.34161 6.46218C1.31251 6.40592 1.28535 6.34384 1.26595 6.27982C1.08746 5.72498 1.30087 5.00717 1.81497 4.25057C1.89839 4.13222 1.98375 4.01582 2.07105 3.90136C2.84706 2.89062 3.83065 2.01373 5.00242 1.3386C6.17419 0.661536 7.4255 0.250252 8.68845 0.0834108C8.83589 0.0620706 8.97945 0.0465509 9.11913 0.0368509C10.0309 -0.0310497 10.7584 0.143552 11.1503 0.574235C11.1969 0.626616 11.2376 0.678996 11.2725 0.733317Z" fill="black" />
  <path d="M15.5252 2.02355C16.728 4.10519 14.301 7.75436 10.1067 10.1755C5.91242 12.5966 1.53574 12.8721 0.334868 10.7905C0.135046 10.4432 0.0283454 10.0572 0.00700524 9.64199C-0.0453752 11.0407 0.195187 12.4628 0.755852 13.813C0.839272 14.0148 0.930453 14.2146 1.02745 14.4125C1.03133 14.4203 1.03715 14.43 1.04103 14.4377C1.40382 15.0643 1.93926 15.5571 2.60663 15.9141C4.95987 17.1731 8.93884 16.7405 12.6598 14.5929C16.3788 12.4453 18.7437 9.21907 18.831 6.55349C18.8562 5.80076 18.6991 5.09266 18.3402 4.46603C18.2044 4.26233 18.0627 4.06251 17.9134 3.87239C17.0306 2.73166 15.9326 1.82373 14.7123 1.17577C15.0518 1.39887 15.3273 1.68017 15.5252 2.02355C15.5252 2.02355 15.5271 2.02549 15.5271 2.02743L15.5252 2.02355Z" fill="black" />
  <path d="M10.0039 19.9977C15.5268 19.9977 20.0039 15.5211 20.0039 9.9989C20.0039 4.4767 15.5268 6.10352e-05 10.0039 6.10352e-05C4.48106 6.10352e-05 0.00390625 4.4767 0.00390625 9.9989C0.00390625 15.5211 4.48106 19.9977 10.0039 19.9977Z" fill="url(#paint0_radial_126_14000)" />
  <defs>
    <radialGradient id="paint0_radial_126_14000"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(10.003 9.99974) scale(9.99977 10.0025)">
      <stop offset="0.5" stop-color="white" stop-opacity="0" />
      <stop offset="1" stop-color="white" stop-opacity="0.35" />
    </radialGradient>
  </defs>
</svg>
</template>

<script>
export default {};
</script>
