export const PHONE_SERVICE_ROUTE = 'phone-service';
export const PHONE_ROUTE = 'phone';
export const PHONE_INFO_ROUTE = 'phone-info';
export const PHONE_STATISTIC_ROUTE = 'phone-statistic';
export const PHONE_STATISTIC_PAYMENTS = 'phone-statistic-payments';
export const PHONE_STATISTIC_BILL = 'phone-statistic-bill';
export const PHONE_STATISTIC_SERVICE = 'phone-statistic-service';
export const PHONE_STATISTIC_DET_CALLS = 'phone-statistic-det-calls;';
export const PHONE_SERVICE_SETTING_ROUTE = 'phone-service-setting';
export const PHONE_TARIFF_ROUTE = 'phone-tariff-route';
export const PHONE_SERVICE_TABLE_ROUTE = 'phone-service-table';
export const PHONE_BLOCK_PAGE = 'phone-block-page';