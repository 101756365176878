<template>
<section class="b-pagination">
  <span class="b-pagination__per-page">
    Строки на странице: {{ pagination.$perPage }}
  </span>
  <span class="b-pagination__range">
    {{ pagination.$viewRange }}
  </span>
  <button @click="prev" :disabled="!pagination.$isPrevPage" class="b-pagination__prev">
    <b-icon name="arrow_left" size="large" />
  </button>
  <button @click="next" :disabled="!pagination.$isNextPage" class="b-pagination__next">
    <b-icon name="arrow_right" size="large" />
  </button>
</section>
</template>

<script setup>
import { BIcon } from 'uikit';

const props = defineProps({
  pagination: { type: Object, required: true },
  paginationApi: { type: Object, required: true }
});
const next = () => props.paginationApi.nextPage();
const prev = () => props.paginationApi.prevPage();
</script>

<style lang="scss">
.b-pagination{
  @include fontStyle(body3);
  color: var(--color-text-active);
  display: flex;
  align-items: center;
  padding-right: 16px;
  justify-content: flex-end;
  height: 52px;

  &__prev,
  &__next {
    all: unset;
    cursor: pointer;

    &:disabled{
      color: var(--color-text-inactive);
    }
  }

  &__next {
    margin-left: 32px;
  }

  &__range{
    margin-right: 71px;
    margin-left: 37px;
  }
}
</style>