<template>
<b-navigation-drawer>
  <template :key="link.text" v-for="link in links">
    <b-navigation-drawer-element-link v-bind="link" />
  </template>
</b-navigation-drawer>
</template>

<script setup>
import { useStore } from 'effector-vue/composition';
import { BNavigationDrawer, BeelineIcons, BNavigationDrawerElementLink } from 'uikit';

import { computed } from 'vue';

import { INTERNET_ROUTE } from '../internet/RouterConst.js';
import { PHONE_ROUTE } from '../phone/RouterConst.js';
import { $services } from '../services/Services.js';
import { TV_ROUTE } from '../tv/RouterConst.js';

const serviceLinks = [
  { coc: 64, text: 'Интернет', to: { name: INTERNET_ROUTE }, icon: BeelineIcons.Wifi },
  { coc: 32, text: 'Кабельное телевидение', to: { name: TV_ROUTE }, icon:  BeelineIcons.TvModern },
  { coc: 4, text: 'Домашний телефон', to: { name: PHONE_ROUTE }, icon: BeelineIcons.Phone }
];
const services = useStore($services);
const links = computed(() => {
  const arr = [{ text: 'Персональные данные', to: { name: 'user' }, icon: BeelineIcons.User }];
  
  for (const service of serviceLinks) {
    const isExist = services.value.some(serviceItem => serviceItem.coc === service.coc && serviceItem.status != null);
    if (isExist) arr.push(service);
  }
  arr.push({ text: 'Новости', to: { name: 'news' }, icon: BeelineIcons.Page });
  return arr;
});

</script>
