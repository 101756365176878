import { attach, createEffect, createStore } from 'effector';

import { createRequestFx } from '../app/http/Http.js';

export const beNuminfo = createEffect(data => createRequestFx({
  data,
  config: {
    path: 'be_numinfo',
    method: 'get'
  }
}));
// export const getServicesFx = createEffect(async () => {
//   // eslint-disable-next-line no-magic-numbers
//   // await timeout(622222000);
// });
export const getServicesFx = attach({ effect: beNuminfo });

export const $services = createStore([]);

export const PHONE_TYPE = '4';
export const TV_TYPE = '32';
export const INTERNET_TYPE = '64';

const getName = serviceTypeId => {
  const services = {
    [INTERNET_TYPE]: 'Интернет-Лайт',
    [TV_TYPE]: 'Кабельное ТВ',
    [PHONE_TYPE]: 'Домашний телефон'
  };
  return services[serviceTypeId] ? services[serviceTypeId] : 'нет данных';
};

$services.on(getServicesFx.doneData, (_, response) => {
  const data = response.data.data;
  const filteredServices = data.services.filter(service => service.nmphnumbertype !== 'web-login');
  return filteredServices.map(service => {
    const fixedSize = 2;
    service.saldo = service.saldo ? `${Number.parseFloat(service.saldo).toFixed(fixedSize)} руб` : null;
    service.servicetype_name = getName(service.coc);
    return service;
  });
});

export const $internetList = $services.map(services => services.filter(service => service.coc == INTERNET_TYPE));
export const $tvList = $services.map(services => services.filter(service => service.coc == TV_TYPE));
export const $phoneList = $services.map(service => service.filter(service => service.coc == PHONE_TYPE));