import { combine, createEffect, createEvent, forward, sample } from 'effector';

import { createRequestFx } from '../app/http/Http.js';

import { $cobplan, $service } from './Tariff.js';

const $data = combine({
  $cobplan, $service
}, ({ $cobplan, $service }) => ({
  v_conumvolume: $service ? $service.conumvolume : null,
  v_cobplannew: $cobplan
}));

const beChangeBpFx = createEffect(data => createRequestFx({
  data,
  config: {
    path: 'be_changebp',
    method: 'post'
  }
}));
export const $loadingBeChangeBpFx = beChangeBpFx.pending;
export const changeBp = createEvent();

sample({
  clock: changeBp,
  source: $data,
  target: beChangeBpFx
});

export const onSuccessFx = createEffect();
forward({
  from: beChangeBpFx.doneData,
  to: onSuccessFx
});

export const onErrorFx = createEffect();
forward({
  from: beChangeBpFx.failData,
  to: onErrorFx
});