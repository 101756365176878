<template>
<section class="b-header">
  <h4 class="b-header__title">Личный кабинет</h4>
  <b-header-logo class="b-header__logo" />
  <b-avatar @click="logout"
            class="b-header__avatar"
            icon="user"
            type="squircle"
            color="grey"
            size="medium" />
  <div class="b-header__divider"></div>
</section>
</template>

<script setup>
import { BAvatar } from 'uikit';
import { useRouter } from 'vue-router';

import BHeaderLogo from './BHeaderLogo.vue';

const router = useRouter();
const logout = () => {
  localStorage.removeItem('token');
  router.push({ name: 'login' });
};

</script>

<style lang="scss">
.b-header{
  height: 64px;
  display: flex;
  padding-left: 24px;
  padding-right: 12px;
  position: relative;
  align-items: center;
  gap: 16px;

  &__logo{
    align-self: center;
    margin-right: 25px;
  }

  &__avatar{
    margin-left: auto;
    align-self: center;
  }

  &__divider{
    background: rgba(0, 0, 0, .12);
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 28px;
    color: rgba(25, 28, 52, .7);
  }
}
</style>
