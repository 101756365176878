import { attach, combine, createEffect, sample } from 'effector';

import { createRequestFx } from '../../app/http/Http.js';
import { getServicesFx } from '../../services/Services.js';
import { $coclient } from '../../user/User.js';
import { $internetService } from '../InternetService.js';

const beStatIpOnOriginalFx = createEffect(data => createRequestFx({
  data,
  config: {
    path: 'be_statip_on',
    method: 'put'
  }
}));

const $data = combine([
  $coclient,
  $internetService
// eslint-disable-next-line camelcase
]).map(([v_userid, service]) => ({
  v_userid,
  v_conumvolume: service ? service.conumvolume : null
}));

export const beStatIpOnFx = attach({
  effect: beStatIpOnOriginalFx,
  source: $data,
  mapParams: (params, data) => ({ ...data, ...params })
});

export const onSuccessFx = createEffect();
sample({
  clock: beStatIpOnFx.doneData,
  target: onSuccessFx
});

export const onErrorFx = createEffect();
sample({
  clock: beStatIpOnFx.failData,
  target: onErrorFx
});

export const $loadingPage = combine({ $loadServices: getServicesFx.pending, $internetService },
  ({ $loadServices, $internetService }) => $loadServices || $internetService === null);