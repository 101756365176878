<template>
<section>
  <b-text class="mt-16" type="h4">Информация о сервисе</b-text>
  <tv-select-service class="mt-16" />
  <service-info @block="goToBlock"
                class="mt-16"
                :loading="loadingTvParams"
                :service="tvServiceWithParams || {}" />
  <b-text class="mt-32" type="h4">Подключенные услуги</b-text>
  <service-tariff v-if="tvService" class="mt-16" :link="TV_SERVICE_SETTING" :service="tvService" />
</section>
</template>

<script setup>

import { useStore } from 'effector-vue/composition.mjs';
import { BText } from 'uikit';

import { useRouter } from 'vue-router';

import ServiceInfo from '../services/ServiceInfo.vue';

import ServiceTariff from '../services/ServiceTariff.vue';

import { TV_SERVICE_SETTING } from './RouterConst.js';
import TvSelectService from './TvSelectService.vue';
import { $tvService, $tvServiceWithParams, getTvServicesWithParams } from './TvService.js';

const tvService = useStore($tvService);
const tvServiceWithParams = useStore($tvServiceWithParams);

const loadingTvParams = useStore(getTvServicesWithParams.pending);

const router = useRouter();
const goToBlock = () => {
  router.push({ name: TV_SERVICE_SETTING });
};
</script>