<template>
<select-service v-if="tvService" :selected-service="tvService" :list="tvList" />
</template>

<script setup>
import { useStore } from 'effector-vue/composition.mjs';

import SelectService from '../services/SelectService.vue';

import { $tvList } from '../services/Services.js';

import { $tvService } from './TvService.js';

const tvService = useStore($tvService);
const tvList = useStore($tvList);
</script>
